.authorization-role {
    width: 430px;
    height: 248px;
    .modal-body {
        font-size: 18px;
    }
    .ant-modal-content {
        width: 430px !important;
        height: 248px;
        border-radius: 8px !important;
        .ant-modal-body {
            width: 430px;
            height: 248px;
            padding: 28px 70px 30px !important;
            display: flex;
            flex-direction: column;
            .title {
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                margin-top: 0 !important;
                margin-bottom: 30px;
            }
            .ant-form{
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .ant-form-item-explain.ant-form-item-explain-error{
                    // text-align: left;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
            .ant-select{
                width: 200px;
            }
            .ant-form-item{
                margin-bottom: 0;
            }
            .btns {
                display: flex;
                justify-content: space-between;
                .btn {
                    width: 120px;
                    height: 40px;
                    border-radius: 6px;
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    text-align: center;
                    cursor: pointer;
                    margin: 0 !important;
                    border: none;
                }
                .btn::after{
                    display: none;
                }
                .btn:nth-of-type(1) {
                    color: #FFFFFF;
                    background: #333333;
                }

                .btn:nth-last-child(1) {
                    color: #999999;
                    background: #DDDDDD;
                }
            }
        }
    }
    .ant-modal-close{
        display: block !important;
    }

}