.test-box{
  width: 100%;
  .test-content{
    width: 100% !important;
    height: 100% !important;
  }
}
// .content{
//   width: 1200px;
//   margin: 0 auto;
//   position: relative;
  #test {
    width: 100% !important;

    // height: 936px;
    height: 100% !important;
  }
//   .swiper-slide {
//     border-radius: 20px;
//     font-size: 18px;
//     // background: red;
//     text-align: center;
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: -webkit-flex;
//     display: flex;
//     -webkit-box-pack: center;
//     -ms-flex-pack: center;
//     -webkit-justify-content: center;
//     justify-content: center;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     -webkit-align-items: center;
//     align-items: center;
//     -webkit-transition: transform 1.0s;
//     -moz-transition: transform 1.0s;
//     -ms-transition: transform 1.0s;
//     -o-transition: transform 1.0s;
//     -webkit-transform: scale(0.7);
//     transform: scale(0.7);
//     // border-radius: 20px;
//     // overflow: hidden;
//   }
//   .swiper-slide-active,.swiper-slide-duplicate-active {
//     border-radius: 20px;
//     -webkit-transform: scale(1);
//     transform: scale(1);
//   }
//   .swiper-button-prev, .swiper-button-next{
//     color: #000;
//   }
//   .swiper-button-prev{
//     left: -65px !important;
//   }
//   .swiper-button-next{
//     right: -65px !important;
//   }
  
// }



