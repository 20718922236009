.supplementalresult {
    width: fit-content;
    margin: 0 auto;
    padding: 72px 0 80px;

    .maintitle {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
    }

    .upload-container {
        .item {
            .ite {
                width: 330px;
                height: 240px;
                border: 2px dashed #4BAD6B;
                margin: 40px 45px 0 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                // justify-content: center;
                padding-top: 23px;

                .title {
                    font-size: 20px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #4BAD6B;
                    margin-bottom: 22px;
                }
                .ant-upload{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                // .ant-upload-list-item-info{
                //     max-width: 200px;
                // }
                // .ant-upload.ant-upload-select{
                //     width: 150px;
                //     height: 110px;
                // }
                .icon-box {
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .btn {
                        width: 100px;
                        height: 36px;
                        background: #4BAD6B;
                        border-radius: 4px;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        text-align: center;
                        // line-height: 36px;
                        margin-top: 18px;
                    }
                    .btndisable{
                        background: #dcdcdc;
                    }
                }
            }

            .tips {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                margin-top: 14px;

                >span {
                    color: #4BAD6B;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }

    .addinfo-container {
        .addinfo-item {
            .title {
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                margin-bottom: 20px;
            }

            .ant-space {
                align-items: center;
                margin-bottom: 20px;

                .ant-space-item {
                    margin-right: 20px;
                    .action-box>i {
                        margin-left: 10px;
                    }
            
                    .addicon,
                    .removeicon {
                        width: 30px;
                        height: 30px;
                        display: inline-block;
                        cursor: pointer;
                    }
            
                    .addicon {
                        background-image: url('../../assets/images/add.png');
            
                        &:hover {
                            background-image: url('../../assets/images/add-active1.png');
                        }
                    }
            
                    .removeicon {
                        background-image: url('../../assets/images/remove.png');
            
                        &:hover {
                            background-image: url('../../assets/images/remove-active1.png');
                        }
                    }
                }
            }

            .ant-form {
                display: flex;
                flex-direction: column;
                .ant-form-item {
                    margin-bottom: 0;
                    .ant-form-item-label {
                        display: flex;
                        align-items: center;
        
                        &>label {
                            margin-right: 34px;
                            font-size: 18px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                        }
                    }
                    .ant-input {
                        width: 360px;
                        height: 46px;
                        background: #FFFFFF;
                        border-radius: 4px;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }
                    .con{
                        width: 360px;
                        height: 46px;
                        line-height: 46px;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }
                    .ant-form-item-control-input-content{
                        display: flex;
                        justify-content: center;
                    }
                    .ant-btn {
                        width: 120px;
                        height: 44px;
                        border-radius: 6px;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        // color: #FFFFFF;
                        margin-top: 10px;
                    }
                }
            }
        }

        .addinfo-item:nth-of-type(1) {
            margin-top: 50px;
        }
        .addinfo-item:nth-of-type(2) {
            .title{
                margin-top: 30px;
            }
        }
        
    }
}