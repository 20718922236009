.teaminvite, .delete-team-info {
    width: 468px;
    // height: 264px;
    height: fit-content;
    .modal-body {
        font-size: 18px;
    }
    .ant-modal-content {
        width: 468px !important;
        // height: 264px;
        height: fit-content;
        border-radius: 8px !important;
        .ant-modal-body {
            width: 468px;
            // height: 264px;
            height: fit-content;
            padding: 28px 20px 30px !important;
            display: flex;
            flex-direction: column;

            .title {
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                margin-top: 0 !important;
                margin-bottom: 20px;
            }

            .info-content {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 22px;
                text-align: left;
            }

            .btns {
                display: flex;
                justify-content: center;
                margin-top: 30px;
                .btn {
                    width: 120px;
                    height: 40px;
                    border-radius: 6px;
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    text-align: center;
                    cursor: pointer;
                    margin: 0;
                    border: none;
                }

                .btn::after {
                    display: none;
                }

                .btn:nth-of-type(1) {
                    color: #FFFFFF;
                    background: #4BAD6B;
                }
                .btn:nth-of-type(2){
                    margin-left: 50px;
                }
                .btn:nth-last-child(1) {
                    color: #999999;
                    background: #DDDDDD;
                }
            }
        }
    }
    .ant-modal-close{
        display: block !important;
    }

}
.delete-team-info{
    width: 468px;
    height: fit-content;
    .ant-modal-content{
        height: fit-content;
        .ant-modal-body{
            height: fit-content;
            .info-content{
                >div{
                    text-align: center;
                }
            }

        }
    }
}