.reading{
    width: 84%;
    padding: 70px 120px;
    box-shadow: 0px 4px 6px 4px #EEF0F1;
    border-radius: 30px;
    margin: 70px auto 120px;
    .title{
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        text-align: center;
    }
    .cont{
        margin-top: 70px;
        word-break: break-all;
    }
}

@media screen and (min-width: 360px) and (max-width: 750px) {
    .reading{
        width: 100%;
        margin-top: 14px;
        padding: 50px 4.7% 0 4.7%;
        border-radius: 0;
        box-shadow: none;
        .cont{
            margin-top: 0;
            word-break: break-all;
        }
    }
}