.des-con{
    // height: fit-content;
    // color: #333333;
    // font-size: 12px;
    overflow: hidden;
    // margin-top: 10px;
    .list{
        .item{
            .title{
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                margin: 30px 0 20px 0;
            }
            .intro{
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 22px;
            }
        }
    } 
}
.des-open{
    height: auto;
}
.open-cont{
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    letter-spacing: 1px;
    cursor: pointer;
    margin-top: 53px;
    img{
        width: 24px;
        height: 13px;
        margin-right: 19px;
    }
}

@media screen and (min-width: 360px) and (max-width: 750px){
    .open-cont{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-top: 15px;
        img{
            width: 10px;
            height: 6px;
            margin-right: 10px;
        }
    }
}