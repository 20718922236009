.challenge-topics-dialog{
    .ant-modal-content{
        width:500px !important;
        border-radius: 20px !important;
        .ant-modal-close{
            color: #FFFFFF;
            display: block;
        }
        .ant-modal-body{
            padding: 0 0 40px 0 !important;
            text-align: left;
            border-radius: 20px !important;

            .title{
                height: 60px;
                background: #4BAD6B;
                font-size: 22px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                text-align: center;
                line-height: 60px;
                border-radius: 20px 20px 0 0
            }
            .url{
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 600;
                color: #333333;
                padding: 0 90px;
                width: fit-content;
                margin-top: 30px;
                display: flex;
                .wikiurl{
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #31A25E;
                    // display: block;
                    flex: 1;
                    word-break: break-all;
                    cursor: pointer;
                }
            }
            .dialog-content{
                width: fit-content;
                // margin: 0 auto;
                padding: 40px 90px 30px;
                .dialog-content-up{
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    .lable{
                        font-size: 18px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 600;
                        color: #333333;
                        margin-right: 20px;
                    }
                    .icon-box{
                        text-align: center;
                        cursor: pointer;
                    }
                    .ant-form-item{
                        margin-bottom: 0;
                        .ant-upload-list-item-name{
                            width: 200px;
                        }
                    }
                    
                }
                .tips{
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #31A25E;
                }
                .reqbox{
                    display: flex;
                    padding-left: 100px;
                    .request{
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #31A25E;
                        margin-left: 10px;
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
                
            }
            .btn-box{
                padding: 0 90px;
                width: fit-content;
                margin: 0 auto;
                display: flex;
                .ant-form-item{
                    margin-bottom: 0;
                }
                .ant-form-item-control-input-content{
                    display: flex;
                    // justify-content: space-between;
                }
                .ok, .cancel{
                    width: 140px;
                    height: 46px;
                    border-radius: 6px;
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    margin-top: 0;
                }
                .ok{
                    background: #31A25E;
                    color: #FFFFFF;
                    margin-right: 40px;
                }
                .cancel{
                    background: #DDDDDD;
                    color: #999999;
                    border-color: #DDDDDD;
                    line-height: 46px;
                    text-align: center;
                    cursor: pointer;
                }
            }
            .btn-box-h5{
                display: none;
            }
        }
    }
    
}

.wiki-topics-dialog{
    .ant-modal-content{
        width: 600px !important;
    }
}

@media screen and (max-width: 750px){
    .challenge-topics-dialog{
        .ant-modal-content{
            width: 325px !important;
            margin: 0 auto;
            border-radius: 12px !important;
            .ant-modal-body{
                padding: 0 !important;
                border-radius: 12px !important;
                overflow: hidden;
                .title{
                    background-color: #FFFFFF;
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    height: 25px;
                    line-height: 25px;
                }
                .url{
                    padding: 0 30px;
                    margin-top: 20px;
                    span{
                        font-size: 16px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 600;
                        color: #333333;
                    }
                    a{
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        // color: #333333;
                        word-break: break-all;
                    }
                }
                .dialog-content{
                    padding: 18px 20px 30px;
                    .dialog-content-up{
                        justify-content: center;
                        .lable{
                            font-size: 16px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 600;
                            color: #333333;
                        }
                        .ant-form-item{
                            .ant-upload-list-item-name{
                                width: 100px;
                            }
                        }
                    }
                }
                .btn-box-h5{
                    padding: 0;
                    width: 100%;
                    display: flex;
                    border-top: 1px solid #D8D8D8;
                    margin-bottom: 0 !important;
                    .ant-form-item-control-input-content{
                        display: flex;
                        // justify-content: space-between;
                        .ant-btn-primary, .ant-btn-primary:focus, .ant-btn-primary:hover, .ant-btn-primary:active{
                            border-color: #FFFFFF;
                            text-shadow: none;
                            box-shadow: none;
                        }
                        .ant-btn-primary::after{
                            display: none;
                        }
                    }
                    .ok, .cancel{
                        width: 50%;
                        border-radius: 0;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        height: 50px;
                    }
                    .ok{
                        margin: 0;
                        color: #44A25A;
                        background-color: #FFFFFF;
                    }
                    .cancel{
                        color: #999999;
                        text-align: center;
                        line-height: 50px;
                        border-right: 1px solid #D8D8D8;
                    }
                }
                .btn-box{
                    display: none;
                }
            }
            .ant-modal-close{
                display: none;
            }
        }
    }
    .wiki-topics-dialog{
        .ant-modal-content .ant-modal-body .dialog-content .dialog-content-up{

        }
        .ant-modal-content .ant-modal-body .dialog-content{
            padding-left: 30px;
            .dialog-content-up{
                justify-content: flex-start;
            }
        }
    }
}