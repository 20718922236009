#root .app-container{
    background-color: #fff;
}
.container{
    width: 1240px;
    margin: 0 auto;
    padding: 0 20px;
    .ant-breadcrumb{
        line-height: 40px;
    }
}
.pc-container-box{
    margin-top: 90px;
}
// 多色的icon
// .symbol_icon{
//     width: 1em;
//     height: 1em;
//     vertical-align: -0.15em;
//     fill: currentColor;
//     overflow: hidden;
//     font-size: 46px;
// }
// modal 弹框关闭 icon
// .ant-modal-close{
//     .icon-modal-close{
//         font-weight: 400;
//         color: #979797;
//     }
// }
// czy 20201116 添加 全局样式： input 输入框 hover focus active的状态边框全部是黑色 
// .ant-input:focus, .ant-input-focused, .ant-input:hover{
//     border-color: #333;
// }

@media (max-width:1440px) {
    .container,.login-con .nologin-container{
        width: 1240px;
        padding: 20px;
    }
}
@media (max-width:1140px) {
    .container,.login-con .nologin-container{
        width: 1240px;
        padding: 20px;
    }
}