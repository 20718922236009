// 顶部 图片
.top-form-bg {
    width: 100%;
    min-width: 1200px;
    height: auto;
    margin-bottom: 50px;
    > img{
        display: inline-block;
        width: 100%;
        height: auto;
    }
}

.innovate-con-form {
    width: 1200px;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px 4px #EEF0F1;
    border-radius: 30px;
    padding: 50px 110px;
    .header-tip{
        color: #4BAD6B;
    }
    > h1 {
        color: #333;
        font-size: 28px;
        line-height: 40px;
        margin: 20px 0 50px;
        font-weight: 600;
    }

    // tab切换
    .ant-tabs {
        .ant-tabs-tab {
            width: 33%;
        }
        > .ant-tabs-nav .ant-tabs-nav-list {
            width: 100%;
        }
        > .ant-tabs-nav .ant-tabs-ink-bar {
            height: 4px;
        }
        .ant-tabs-tab .ant-tabs-tab-btn {
            font-size: 22px;
            color: #333;
            font-weight: 500;
            font-family: PingFangSC-Medium, PingFang SC;
        }
    }
    
}

.innovate-form {
    .ant-form-item-control-input-content{
        line-height: 50px;
        font-size: 18px;
    }
    .double-input-div{
        font-size: 18px;
    }
    .ant-row.ant-form-item {
        margin-bottom: 14px;
        .ant-form-item-label > label{
            font-size: 20px;
            padding-right: 20px;
            height: 50px;
            text-align: right;
            > i {
                font-style: normal;
                // text-align: left;
            }
            &::after{
                display: none;
            }
        }
        // 去掉必填项前面的红点
        .ant-form-item-required {
            &::before,&::after {
                display: none;
            }
        }
        .ant-input {
            height: 50px;
            line-height: 50px;
        }
        .ant-input[readOnly] {
            border-color: rgba($color: #eee, $alpha: .5);
            cursor: not-allowed;
            &:focus,&:hover,&:active {
                border-color: rgba($color: #eee, $alpha: .5);
                box-shadow:none;
            }
        }
        textarea.ant-input {
            line-height: 24px;
        }
    }

    .ant-input,.ant-picker-input > input {
        font-size: 18px;
    }
    .ant-select.ant-select-single {
        width: 22%;
        margin-right: 20px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 50px;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input,
    .ant-select-single .ant-select-selector .ant-select-selection-item {
        line-height: 50px;
        height: 50px;
        font-size: 16px;
    }
    // 双输入框 margin
    .double-input {
        margin-top: 30px;
    }

}

// 输入框的下面的提示 样式
.btm-tip {
    color: #4BAD6B;
    font-size: 14px;
    display: inline-block;
    margin: 10px 0;
    margin-left: 29%;
}

// 底部button按钮
.button-box {
    margin-top: 80px;
    text-align: center;
    .ant-btn.ant-btn-primary{
        height: 54px;
        line-height: 54px;
        border-radius: 6px;
        width: 160px;
        padding: 0;
        font-size: 18px;
        > span {
            color: #fff;
        }
    }
    .ant-btn.ant-btn-primary[disabled] {
        color: white;
    }
}


.busi-form {
    .group-radio.ant-row.ant-form-item .ant-form-item-label > label{
        padding-right: 50px;
    }
    // 选择组别
    .ant-radio-wrapper {
        display: block;
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        .ant-radio {
            vertical-align: middle;
        }
        span.ant-radio + * {
            padding-left: 15px;
        }
    }

    .basic-btm-tip{
        margin-left: 29%;
    }
    // 上传
    .ant-upload.ant-upload-select {
        width: 145px;
        height: 140px;
        margin-right: 60px;
        margin-bottom: 10px;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px 4px #EEF0F1;
        border-radius: 6px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        > span.ant-upload {
            text-align: center;
        }
    }
    .ant-upload.ant-upload-select + .ant-upload-list {
        width: 150px;
    }
    // .ant-space-item .ant-form-item-explain.ant-form-item-explain-error {
    //     margin-top: 10px;
    // }
}

// 增加按钮
.nofund-team-space {
    width: 100%;
    > .ant-space-item {
        &:first-child{
            width: 100%;
        }
        &:last-child{
            margin-left: -90px;
            margin-top: 38px;
            .action-box > i{
                margin-left: 10px;
            }
        }
        .addicon,.removeicon {
            width: 30px;
            height: 30px;
            display: inline-block;
            cursor: pointer;
        }
        .addicon {
            background-image: url('../../assets/images/add.png');
            &:hover {
                background-image: url('../../assets/images/add-active1.png');
            }
        }
        .removeicon {
            background-image: url('../../assets/images/remove.png');
            &:hover{
                background-image: url('../../assets/images/remove-active1.png');
            }
        }
        
    }
}

.company-tip {
    color: #31A25E;
    font-size: 16px;
    margin: 20px 0;
}

.sector-checkbox {
    .ant-checkbox-group {
        padding-top: 10px;
    }
    .ant-checkbox-wrapper {
        width: 100%;
        margin-left: 0;
        line-height: 32px;
        color: #666;
        font-size: 15px;
    }
}

.ant-picker {
    height: 50px;
    width: 100%;
    .ant-picker-suffix {
        color: #31A25E;
        font-size: 16px;
    }
}


// 融资表单
.fund-con {
    .ant-tabs {
        .ant-tabs-tab {
            width: 50%;
        }
    }
}

.modal-tip{
    width: 640px !important;
    .ant-modal-body{
        .human{
            object-fit: contain;
        }
        .ant-btn-primary {
            line-height: 60px;
            padding: 0;
        }
    }
}

@media (max-width: 1199px) {
    .innovate-con-form,.business-nofund {
        width: 100%;
        box-shadow: none;
        border-radius: 0;
        padding: 40px;
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden, 
        .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden{
            display: none;
        }
    }
    .top-form-bg {
        width: 100%;
        min-width: 0;
    }
    .innovate-form .ant-row.ant-form-item .ant-form-item-label > label {
        font-size: 18px;
        padding-right: 10px;
        white-space: break-spaces;
    }
    .innovate-form .ant-input, .innovate-form .ant-picker-input > input {
        font-size: 16px;
    }
    .nofund-team-space > .ant-space-item:last-child {
        margin-left: -70px;
        margin-top: 42px;
    }
    .nofund-team-space > .ant-space-item:last-child .action-box > i {
        margin-left: 5px;
    }
    .nofund-team-space {
        .ant-space-item {
            .addicon,.removeicon {
                width: 25px;
                height: 25px;
                background-size: 100%;
                display: inline-block;
            }
        }
    }
    h2.file-h2 {
        font-size: 18px;
        font-weight: normal !important;
    }
}
@media screen and (min-width: 751px) and (max-width: 998px) {
    .modal-tip.ant-modal{
        width: 60% !important;
        .ant-modal-content{
            width: 100%;
        }
        .ant-modal-body{
            padding: 40px !important;
            .human{
                width: 70%;
                height: auto;
            }
            .ant-btn-primary{
                width: 100%;
            }
        }
    }
    .innovate-con-form,.business-nofund {
        padding: 40px 20px;
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden, 
        .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden{
            display: none;
        }
    }
    h2.file-h2 {
        font-size: 16px;
        font-weight: normal;
    }
    .innovate-form .ant-row.ant-form-item .ant-form-item-label > label {
        font-size: 15px;
        padding-right: 10px;
        white-space: break-spaces;
    }
    .innovate-form .ant-input, .innovate-form .ant-picker-input > input {
        font-size: 14px;
    }

    .innovate-con-form .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
        font-size: 18px;
    }
    .innovate-con-form .ant-tabs > .ant-tabs-nav .ant-tabs-ink-bar {
        height: 2px;
    }
    .button-box  {
        margin-top: 40px;
        .ant-btn.ant-btn-primary{
            width: 120px;
        }
    }
    .nofund-team-space > .ant-space-item {
        &:first-child{
            width: 90%;
        }
        &:last-child {
            width: 10%;
            margin-left:-30px;
            margin-top:42px;
        }
    }
    .nofund-team-space {
        .ant-space-item {
            .addicon,.removeicon {
                width: 22px;
                height: 22px;
                background-size: 100%;
                display: inline-block;
            }
        }
    }
}
@media (max-width: 768px) {
    .btm-tip,.busi-form .basic-btm-tip {
        margin-left: 33%;
        width: 60%;
    }
}
@media (max-width:576px) {
    .btm-tip,.busi-form .basic-btm-tip {
        width: 100%;
        margin-left: 0;
        font-size: 13px;
    }
}
@media (max-width:480px) {
    .btm-tip,.busi-form .basic-btm-tip {
        font-size: 12px;
    }
}
@media (max-width: 751px) {
    .modal-tip.ant-modal{
        width: 70% !important;
        .ant-modal-content{
            width: 100%;
        }
        .ant-modal-body{
            padding: 30px !important;
            .human{
                width: 70%;
            }
            .title {
                margin-top: 20px;
            }
            .title2{
                margin-top: 10px;
                font-size: 14px;
                line-height: 1.4;
            }
            .ant-btn-primary{
                margin-top: 30px;
                width: 100%;
                line-height: 46px;
                height: 46px;
                font-size: 20px;
            }
        }
    }
    .top-form-bg {
        margin-bottom: 30px;
        margin-top: 44px;
    }
    h2.file-h2 {
        font-size: 14px;
        font-weight: normal;
    }
    .company-tip {
        font-size: 12px;
        margin: 10px 0;
    }
    .innovate-con-form,.business-nofund{
        padding: 20px;
        margin: 0;
        > h1 {
            font-size: 20px;
            margin: 10px 0 20px;
        }
        .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
            font-size: 16px;
        }
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden, 
        .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden{
            display: none;
        }
        .ant-row.ant-form-item .ant-form-item-label > label {
            font-size: 14px;
            padding-right: 10px;
            white-space: break-spaces;
        }
        .ant-input, .ant-picker-input > input {
            font-size: 12px;
        }
        .ant-row.ant-form-item .ant-input {
            height: 36px;
            line-height: 36px;
        }
        .ant-row.ant-form-item {
            margin-bottom: 2px;
        }
        .double-input {
            margin-top: 15px;
        }
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
            height: 36px;
            margin-top: 7px;
        }
        .ant-select-single .ant-select-selector .ant-select-selection-placeholder, 
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input, 
        .ant-select-single .ant-select-selector .ant-select-selection-item {
            height: 36px;
            line-height: 36px;
            font-size: 14px;
        }
    }
    .innovate-form .ant-row.ant-form-item .ant-form-item-label > label {
        font-size: 14px;
        padding-right: 10px;
        white-space: break-spaces;
    }
    // .innovate-form .ant-form-item-control-input-content,
    .innovate-form .ant-row.ant-form-item .ant-input {
        line-height: 36px;
        height: 36px;
        font-size: 14px;
    }
    .innovate-form .double-input-div {
        font-size: 14px;
    }
    .busi-form{
        .ant-upload.ant-upload-select{
            width: 100px;
            height: 100px;
            margin-right: 20px;
            img{
                width: 60%;
            }
        }
    }
    .nofund-team-space > .ant-space-item:last-child {
        margin-top:-11px
    }
    .busi-form .ant-radio-wrapper {
        font-size: 14px;
    }
    
    .button-box {
        margin-top: 40px;
        .ant-btn.ant-btn-primary {
            width: 100px;
            height: 42px;
            line-height: 42px;
            font-size: 16px;
        }
    }
}

@media (max-width:576px){
    .innovate-con-form,.business-nofund{
        padding: 15px;
        .ant-tabs-tab {
            margin-right: 20px;
        }
    }
    .nofund-team-space > .ant-space-item:last-child {
        margin-top: 28px;
        margin-left: 0;
    }
    .modal-tip{
        width: 70% !important;
        .ant-modal-body{
            padding: 20px !important;
            .human{
                width: 60%;
            }
            .title {
                margin-top: 15px;
                font-size: 20px;
            }
            .title2{
                margin-top: 10px;
                font-size: 14px;
                line-height: 1.4;
            }
            .ant-btn-primary{
                margin-top: 30px;
                width: 100%;
                line-height: 46px;
                height: 46px;
                font-size: 20px;
            }
        }
    }
}

@media (max-width: 480px) {
    // 弹框
    .modal-tip{
        width: 80% !important;
        .ant-modal-body{
            padding: 20px !important;
            .human{
                width: 60%;
                height: auto;
            }
            .title {
                margin-top: 15px;
                font-size: 16px;
            }
            .title2{
                margin-top: 10px;
                font-size: 12px;
                line-height: 1.4;
            }
            .ant-btn-primary{
                margin-top: 30px;
                width: 100%;
                line-height: 42px;
                height: 42px;
                font-size: 14px;
            }
        }
    }
    .top-form-bg {
        margin-bottom: 0;
    }
    .innovate-con-form, .business-nofund {
        .double-input {
            margin-top: 5px;
        }
    }
    .busi-form .ant-radio-wrapper {
        font-size: 14px;
        line-height: 36px;
        height: 36px;
    }
    .innovate-con-form .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn, 
    .business-nofund .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn{
        font-size: 13px;
    }
    .innovate-con-form .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list{
        width: auto;
    }
    .innovate-form .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 36px;
    }
    .innovate-form .ant-select-single .ant-select-selector .ant-select-selection-placeholder, 
    .innovate-form .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input, 
    .innovate-form .ant-select-single .ant-select-selector .ant-select-selection-item{
        height: 36px;
        line-height: 36px;
        font-size: 12px;
    }
    .nofund-team-space > .ant-space-item:last-child{
        margin-left: -10px;
    }
    .nofund-team-space .ant-space-item .addicon, 
    .nofund-team-space .ant-space-item .removeicon{
        width: 16px;
        height: 16px;
    }
}
.file-h2{
    font-weight: normal ;
}
