.submitModal {
    width: 500px !important;
    .modal-body {
        font-size: 18px;
    }

    .ant-modal-body {
        padding: 30px 74px !important;
    }

    .ant-modal-content {
        width: 100%;
    }

    .btns {
        display: flex;
        justify-content: space-around;

        .btn {
            font-size: 18px;
            width: 100px;
            letter-spacing: 0;
            height: 40px;

            &:nth-last-child(1) {
                color: #999;
                background-color: #fff;
                border: 1px solid #999999;
            }

            >span {
                line-height: normal;
            }
        }
    }
}
@media (max-width: 998px){
    .submitModal{
        width: 50% !important;
    }
}

@media (max-width: 751px) {
    .submitModal{
        width: 55% !important;
        .ant-modal-body{
            padding: 30px !important;
        }
        .modal-body{
            font-size: 16px;
        }
        .btns .btn {
            font-size: 16px;
            margin-top: 30px;
        }
    }
}

@media (max-width: 480px){
    .submitModal{
        width: 70% !important;
        .ant-modal-body{
            padding: 20px !important;
        }
        .modal-body{
            font-size: 14px;
        }
        .btns .btn {
            font-size: 14px;
            margin-top: 30px;
            width: 80px;
            height: 32px;
        }
    }
}