.nav-container{
    width: 100%;
    box-shadow: 0px 2px 4px 0px rgba(225, 232, 243, 0.5);
    background-color: #ffffff;
    position: fixed;
    top: 0;
    z-index: 2;
    .nav-con{
        display: flex;
        margin: 0 auto ;
        // padding: 30px 0 30px 0;
        // width: 1200px;
        padding: 30px 8%;
        .nav-logo{
            width: 170px;
            height: 30px;
            // height: 17px;
            // margin-left: -120px;
        }
        .nav-logo2{
            width: 151px;
            height: 30px;
            // height: 17px;
            // margin-left: -120px;
        }
        .nav-right{
            flex: 1;
            display: flex;
            justify-content: space-between;
            .nav-list{
                display: flex;
                .nav-item{    
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    margin-left: 60px;
                    position: relative;
                    a,.links{
                        padding-bottom: 30px;
                        color: #333333;
                    }
                    .linkantd, .links{
                        &:after {
                            content: '';
                            position: absolute;
                            bottom: -30px;
                            left: 50%;
                            margin-left: -20px;
                            height: 4px;
                            width: 40px;
                            background-color: #ffffff;
                        }
                    }
                    cursor: pointer;
                    .nav-child, .nav-child2{
                        display: none;
                        // background-color: red;
                        position: absolute;
                        left: 50%;
                        margin-left: -50px;
                        top: 60px;
                        width: 100px;
                        text-align: center;
                        padding-bottom: 12px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 20px;
                        z-index: 10;
                        background: #FFFFFF;
                        // position: relative;
                        >div{
                            margin-top: 12px;
                        }
                        >div:nth-of-type(1){
                            margin-top: 16px;
                        }
                        >div:hover, .child-links-active{
                            color: #31A25E;
                        }
                    }
                    .nav-child2{
                        top: 60px;
                    }
                    
                    .links-active{
                        color: #31A25E;
                        &:after {
                            background-color: #31A25E;
                        }
                    }
                    
                }
                .nav-item:hover,.nav-item-active{
                    .nav-child{
                        display: block;
                    }
                    a,.links{
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #31A25E;
                    }
                    .linkantd, .links{
                        &:after {
                            background-color: #31A25E !important;
                        }
                    }
                }
                .linkantd-active{
                    &:after {
                        background-color: #31A25E !important;
                    }
                }
                
            }
            .nav-login{
                display: flex;
                .infocen{
                    margin-right: 50px;
                    position: relative;
                    .num{
                        position: absolute;
                        right: -6px;
                        top: -5px;
                        width: 18px;
                        height: 18px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        background: url('../../assets/images/circle.png') no-repeat;
                        font-size: 12px;
                        color: #fff;
                    }
                }
                a{
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #31A25E;
                }
                .no-login{
                   >a:nth-last-of-type(1){
                        padding-left: 40px;
                    } 
                }
                
                .person{
                    position: relative;
                    >img{
                        cursor: pointer;
                    }
                    .person-list{
                        position: absolute;
                        left: 50%;
                        margin-left: -50px;
                        width: 100px;
                        display: none;
                        z-index: 10;
                        text-align: center;
                        padding-top: 15px;
                        background-color: #FFFFFF;
                        .person-item{
                            cursor: pointer;
                            // color: #31A25E;
                            padding: 10px 0;
                        }
                        .person-item:hover{
                            color: #31A25E;
                        }
                    }
                }
                .person:hover, .nav-login:hover, .person>img:hover{
                    .person-list{
                        display: block;
                    }
                }
            }
            
        }
        
    }
}

@media screen and (min-width: 1000px) and (max-width: 1300px){
    .nav-container{
        // background-color: red;
        .nav-con{
            width: 100%;
            padding: 30px 8%;
            .nav-right{
                .nav-list{
                    flex: 1;
                    padding: 0 50px ;
                    display: flex;
                    justify-content: space-between;
                    .nav-item{
                        margin-left: 0;
                    }
                }
            }
            
        }
    }
    
}

@media screen and (min-width: 751px) and (max-width: 1440px){
    .nav-container{
        // background-color: red;
        .nav-con{
            width: 100%;
            padding: 30px 8%;
        }
    }
    
}

@media screen and (min-width: 751px) and (max-width: 1080px){
    .nav-container{
        // background-color: red;
        .nav-con{
            width: 100%;
            .nav-right{
                .nav-list{
                    padding-left: 30px;
                    justify-content: space-between;
                    flex: 1;
                    padding-right: 10px;
                    .nav-item{
                        margin-left: 0;
                    }
                }
                .nav-login{

                }
            }
        }
    }
}

@media screen and (min-width: 751px) and (max-width: 850px){
    .nav-container{
        .nav-con{
            .nav-right{
                .nav-list{
                    padding-left: 10px;
                    .nav-item{
                        font-size: 16px;
                    }
                }
            }
            
        }
    }
    
}