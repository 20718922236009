body {
  margin: 0;
  font-family: PingFangSC-Regular, PingFang SC, -apple-system, BlinkMacSystemFont, Segoe UI, Hiragino Sans GB, Microsoft YaHei, 'Helvetica Neue', Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::selection {
  color: #fff;
  background-color: #1890ff !important;
}

::-moz-selection {
  color: #fff;
  background-color: #1890ff !important;
}

y::-webkit-selection {
  color: #fff;
  background-color: #1890ff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// 颜色自定义
$primary_color:#121F3D;

ul {
  list-style: none;
  padding-left: 0;
}

.box_shadow {
  background-color: #fff;
  box-shadow: 0px 2px 10px 0px rgba(240, 242, 247, 1);
  border-radius: 12px;
}

.flex_box {
  display: flex;
  display: -webkit-flex;
}

@import 'styles/home';