@media screen and (min-width: 1440px) {
    .sign-up-challenge-h5 {
        display: none;
    }

    .sign-up-challenge {
        .challenge {
            background: url('../../assets/images/bianzu2.png') no-repeat;
            background-size: 100% 100%;
            height: 508px;

            .challenge-content {
                padding-top: 90px;
                width: 1000px;
                margin: 0 auto;

                .title {
                    font-size: 32px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    text-align: center;
                    // margin-bottom: 30px !important;
                }
                .entitle{
                    font-size: 28px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    text-align: center;
                    margin-bottom: 30px;
                }
                .challenge-body {
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }
                .btnurl{
                    display: block;
                    width: fit-content;
                    margin: 34px auto 0;
                    .btn{
                        width: 230px;
                        height: 70px;
                        background: #FFFFFF;
                        border-radius: 8px;
                        font-size: 24px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        line-height: 70px;
                        letter-spacing: 1px;
                        text-align: center;
                        color: #000;
                        cursor: pointer;
                    }
                }
                
            }
        }

        .time {
            height: 626px;
            background: url('../../assets/images/back4.png') no-repeat;
            background-size: 100% 100%;
            margin-top: -161px;
            padding-top: 182px;

            .title {
                font-size: 30px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 42px;
                letter-spacing: 2px;
                text-align: center;
                // margin-bottom: 70px;
                margin: 0 auto 70px;
                width: fit-content;
                position: relative;
                span{
                    position: absolute;
                    bottom: 0;
                    left: 2px;
                }
                .bor {
                    width: 100px;
                    height: 27px;
                    background: #E4200C;
                    opacity: .5;
                    // position: absolute;
                    // left: calc(50% - 48px);
                    // margin-top: -21px;
                }
            }

            .time-img {
                padding: 0 17%;
                text-align: center;

                >img {
                    max-width: 100%;
                }
            }
        }

        .timetable{
            position: relative;
            display: flex;
            justify-content: center;
            width: 100%;
            height: 465px;
            background: url(../../assets/images/time.png) no-repeat;
            background-size: 100% 100%;
            .bg{
                width: 100%;
                // margin-top: -100px;
            }
            .content{
                // position: absolute;
                // top: 0;
                text-align: center;
                width: 100%;
                .title{
                    font-size: 30px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 42px;
                    letter-spacing: 2px;
                    position: relative;
                    margin: 0 auto;
                    width: fit-content;
                    span{
                        position: absolute;
                        bottom: 0;
                        left: 2px;
                    }
                    .bor{
                        width: 100px;
                        height: 27px;
                        background: #E4200C;
                        opacity: 0.5;
                        // position: absolute;
                        // bottom: 0;
                        // left: 50%;
                        // margin-left: -50px;
                    }
                }
                .timeimg{
                    margin: 69px auto 0;
                    max-width: 1140px;
                }
            }
            
        }
        .apply {
            display: flex;
            flex-wrap: wrap;
            margin: 0 auto;
            width: 1140px;

            .apply-item {
                width: 285px;
                height: 356px;

                >img {
                    width: 285px;
                    height: 178px;
                }

                &:nth-child(2n+1) {
                    .apply-btn {
                        padding-left: 16px;

                        >img {
                            margin-top: 12px;
                        }

                        >div {
                            margin-top: 12px;
                            font-size: 18px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                            line-height: 25px;
                        }

                        .apply-yes {
                            display: block;
                            margin: 0 auto;
                            margin-top: 12px;
                            width: 110px;
                            height: 42px;
                            background: #E4200C;
                            border-radius: 8px;
                            font-size: 16px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                            border: none;
                            cursor: pointer;
                        }

                        .apply-no {
                            display: block;
                            margin: 0 auto;
                            margin-top: 12px;
                            width: 110px;
                            height: 42px;
                            background: #dddddd;
                            border-radius: 8px;
                            border: none;
                            font-size: 16px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #999999;
                            cursor: pointer;
                        }
                    }
                }

                &:nth-child(2n) {
                    .apply-btn {
                        width: 285px;
                        height: 178px;
                        padding-left: 16px;

                        >img {
                            margin-top: 40px;
                        }

                        >div {
                            padding-top: 24px;
                            font-size: 18px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                        }

                        .apply-yes {
                            display: block;
                            margin: 0 auto;
                            margin-top: 12px;
                            width: 110px;
                            height: 42px;
                            background: #E4200C;
                            border-radius: 8px;
                            font-size: 16px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                            border: none;
                            cursor: pointer;
                        }

                        .apply-no {
                            display: block;
                            margin: 0 auto;
                            margin-top: 12px;
                            width: 110px;
                            height: 42px;
                            background: #dddddd;
                            border-radius: 8px;
                            border: none;
                            font-size: 16px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #999999;
                            cursor: pointer;
                        }
                    }
                }
            }

            .apply-item-other {
                width: 570px;
                height: 178px;
                display: flex;

                >img {
                    width: 285px;
                    height: 178px;
                }

                .apply-btn-other {
                    width: 285px;
                    height: 178px;
                    padding-left: 16px;

                    >img {
                        margin-top: 12px;
                    }

                    >div {
                        margin: 12px 0;
                    }

                    .apply-yes {
                        display: block;
                        margin: 0 auto;
                        margin-top: 12px;
                        width: 110px;
                        height: 42px;
                        background: #E4200C;
                        border-radius: 8px;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        border: none;
                        cursor: pointer;
                    }

                    .apply-no {
                        display: block;
                        margin: 0 auto;
                        margin-top: 12px;
                        width: 110px;
                        height: 42px;
                        background: #dddddd;
                        border-radius: 8px;
                        border: none;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        cursor: pointer;
                    }
                }
            }
        }

        .guide {
            >img {
                width: 100%;
                height: 893px;
            }

            .guide-center {
                position: relative;
                margin-top: -780px;
            }

            .guide-body {
                width: 1200px;
                margin: 0 auto;

                .guide-title {
                    >img {
                        width: 17px;
                        height: 40px;
                        margin-right: 20px;
                        margin-top: -2px;
                    }

                    font-size: 30px;
                    font-family: PingFangSC-Medium,
                    PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 93px;
                    text-shadow: 0px 0px #000;
                }

                .des-con {
                    .list {
                        padding: 0 37px;
                    }
                }
            }
        }

        .review {
            .review-body {
                width: 1200px;
                margin: 0 auto;

                .review-title {
                    >img {
                        width: 17px;
                        // height: 93px;
                        margin-right: 20px;
                    }

                    font-size: 30px;
                    font-family: PingFangSC-Medium,
                    PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 93px;
                    text-shadow: 0px 0px #000;
                }

                .des-con {
                    .list {
                        padding: 0 37px;
                    }
                }
            }
        }

        .awards {
            background: url('../../assets/images/awards.png') no-repeat;
            background-size: 100% 100%;
            height: 795px;
            margin: 0 auto;

            .awards-content {
                width: 1200px;
                margin: 0 auto;

                .awards-title {

                    >img {
                        width: 17px;
                        // height: 93px;
                        margin-right: 20px;
                    }

                    font-size: 30px;
                    font-family: PingFangSC-Medium,
                    PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 93px;
                    text-shadow: 0px 0px #000;
                }

                .awards-body {
                    display: flex;
                    justify-content: space-around;
                    margin-top: 31px;

                    .awards-item {
                        width: 200px;
                        margin-right: 200px;

                        .awards-back {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 200px;
                            height: 200px;

                            >img {
                                width: 100px;
                                height: 100px;
                            }
                        }

                        .rank {
                            font-size: 24px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                            margin: 30px 0;
                            text-align: center;
                        }

                        .awards-intro {
                            font-size: 16px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                            line-height: 26px;
                            text-align: center;
                        }

                        &:nth-last-child(1) {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 751px) and (max-width: 1439px) {
    .sign-up-challenge-h5 {
        display: none;
    }

    .sign-up-challenge {
        .challenge {
            background: url('../../assets/images/bianzu2.png') no-repeat;
            background-size: 100% 100%;
            height: 508px;

            .challenge-content {
                padding-top: 90px;

                .title {
                    font-size: 32px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    text-align: center;
                }
                .entitle{
                    font-size: 28px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    text-align: center;
                    margin-bottom: 30px;
                }
                .challenge-body {
                    width: 69.4%;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin: 0 auto;
                }
                .btnurl{
                    display: block;
                    width: fit-content;
                    margin: 34px auto 0;
                    .btn{
                        width: 230px;
                        height: 70px;
                        background: #FFFFFF;
                        border-radius: 8px;
                        font-size: 24px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        line-height: 70px;
                        letter-spacing: 1px;
                        text-align: center;
                        color: #000;
                        cursor: pointer;
                    }
                }
            }
        }

        .time {
            height: 626px;
            background: url('../../assets/images/back4.png') no-repeat;
            background-size: 100% 100%;
            margin-top: -161px;
            padding-top: 182px;

            .title {
                font-size: 30px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 42px;
                letter-spacing: 2px;
                text-align: center;
                // margin-bottom: 70px;
                position: relative;
                width: fit-content;
                margin: 0 auto 70px;
                span{
                    position: absolute;
                    bottom: 0;
                    left: 2px;
                }
                .bor {
                    width: 100px;
                    height: 27px;
                    background: #E4200C;
                    opacity: .5;
                    // position: absolute;
                    // left: calc(50% - 48px);
                    // margin-top: -21px;
                }
            }

            .time-img {
                padding: 0 17%;
                text-align: center;

                >img {
                    max-width: 100%;
                }
            }
        }

        .timetable{
            // position: relative;
            display: flex;
            justify-content: center;
            width: 100%;
            height: 465px;
            background: url(../../assets/images/time.png) no-repeat;
            background-size: 100% 100%;
            padding: 0 17%;

            .bg{
                width: 100%;
                // margin-top: -100px;
            }
            .content{
                // position: absolute;
                // top: 0;
                text-align: center;
                width: 100%;
                .title{
                    font-size: 30px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 42px;
                    letter-spacing: 2px;
                    position: relative;
                    width: fit-content;
                    margin: 0 auto;
                    span{
                        position: absolute;
                        bottom: 0;
                        left: 2px;
                    }
                    .bor{
                        width: 100px;
                        height: 27px;
                        background: #E4200C;
                        opacity: 0.5;
                        // position: absolute;
                        // bottom: 0;
                        // left: 50%;
                        // margin-left: -50px;
                    }
                }
                .timeimg{
                    margin: 69px auto 0;
                    width: 100%;
                }
            }
            
        }
        .apply {
            display: flex;
            flex-wrap: wrap;
            padding: 0 10.4%;
            width: 100%;

            .apply-item {
                width: 25%;
                height: 356px;

                >img {
                    width: 100%;
                    height: 50%;
                }

                &:nth-child(2n+1) {
                    .apply-btn {
                        height: 50%;

                        >img {
                            padding: 4%;
                        }

                        >div {
                            padding-left: 4%;
                            font-size: 18px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                            line-height: 25px;
                        }

                        .apply-yes {
                            display: block;
                            margin: 10% auto;
                            margin-top: 12px;
                            width: 110px;
                            height: 42px;
                            background: #E4200C;
                            border-radius: 8px;
                            font-size: 16px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                            border: none;
                            cursor: pointer;
                        }

                        .apply-no {
                            display: block;
                            margin: 10% auto;
                            margin-top: 12px;
                            width: 110px;
                            height: 42px;
                            background: #dddddd;
                            border-radius: 8px;
                            border: none;
                            font-size: 16px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #999999;
                            cursor: pointer;
                        }
                    }
                }

                &:nth-child(2n) {
                    .apply-btn {
                        height: 50%;
                    }

                    >div {
                        padding: 4%;
                        font-size: 18px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        line-height: 25px;
                    }

                    .apply-yes {
                        display: block;
                        margin: 34px 20%;
                        width: 110px;
                        height: 42px;
                        background: #E4200C;
                        border-radius: 8px;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        border: none;
                        cursor: pointer;
                    }

                    .apply-no {
                        display: block;
                        margin: 34px 20%;
                        width: 110px;
                        height: 42px;
                        background: #dddddd;
                        border-radius: 8px;
                        border: none;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        cursor: pointer;
                    }
                }
            }
            .apply-item-other {
                display: flex;
                .apply-btn-other {
                    width: 285px;
                    height: 178px;
                    padding-left: 16px;

                    >img {
                        margin-top: 12px;
                    }

                    >div {
                        margin: 12px 0;
                    }

                    .apply-yes {
                        display: block;
                        margin: 0 auto;
                        margin-top: 12px;
                        width: 110px;
                        height: 42px;
                        background: #E4200C;
                        border-radius: 8px;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        border: none;
                        cursor: pointer;
                    }

                    .apply-no {
                        display: block;
                        margin: 0 auto;
                        margin-top: 12px;
                        width: 110px;
                        height: 42px;
                        background: #dddddd;
                        border-radius: 8px;
                        border: none;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        cursor: pointer;
                    }
                }
            }
        }

        .guide {
            >img {
                width: 100%;
                height: 893px;
            }

            .guide-center {
                padding-left: 8.1%;
                position: relative;
                margin-top: -780px;
            }

            .guide-body {

                .guide-title {
                    >img {
                        width: 17px;
                        height: 40px;
                        margin-right: 20px;
                        margin-top: -2px;
                    }

                    font-size: 30px;
                    font-family: PingFangSC-Medium,
                    PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 93px;
                    text-shadow: 0px 0px #000;
                }

                .des-con {
                    .list {
                        padding: 0 37px;
                    }
                }
            }
        }

        .review {
            padding-left: 8.1%;

            .review-body {

                .review-title {
                    >img {
                        width: 17px;
                        // height: 93px;
                        margin-right: 20px;
                        margin-top: -2px;
                    }

                    font-size: 30px;
                    font-family: PingFangSC-Medium,
                    PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 93px;
                    text-shadow: 0px 0px #000;
                }

                .des-con {
                    .list {
                        padding: 0 37px;
                    }
                }
            }
        }

        .awards {
            background: url('../../assets/images/awards.png') no-repeat;
            background-size: 100% 100%;
            height: 795px;

            .awards-content {

                .awards-title {
                    padding-left: 8.1%;

                    >img {
                        width: 17px;
                        // height: 93px;
                        margin-right: 20px;
                        margin-top: -2px;
                    }

                    font-size: 30px;
                    font-family: PingFangSC-Medium,
                    PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 93px;
                    text-shadow: 0px 0px #000;
                }

                .awards-body {
                    display: flex;
                    margin-top: 31px;
                    padding: 0 15.7%;
                    justify-content: center;


                    .awards-item {
                        width: 30%;
                        margin-right: 5%;

                        .awards-back {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 200px;
                            height: 200px;
                            margin: 0 auto;

                            >img {
                                width: 100px;
                                height: 100px;
                            }
                        }

                        .rank {
                            font-size: 24px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                            margin: 30px 0;
                            text-align: center;
                        }

                        .awards-intro {
                            font-size: 16px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                            line-height: 26px;
                            text-align: center;
                        }

                        &:nth-last-child(1) {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 360px) and (max-width: 750px) {
    .sign-up-challenge {
        display: none;
    }

    .sign-up-challenge-h5 {
        margin-top: 44px;
        display: block;

        .challenge {
            background: #000000;
            opacity: .89;
            width: 100%;
            padding: 30px 20px 20px;

            .challenge-content {
                .title, .entitle {
                    text-align: center;
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    // line-height: 50px;
                }
                .entitle{
                    margin-bottom: 15px;
                }
                .challenge-body {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 22px;
                }
                .btnurl{
                    display: block;
                    width: fit-content;
                    margin: 34px auto 0;
                    .btn{
                        width: 118px;
                        height: 36px;
                        background: #FFFFFF;
                        box-shadow: 0px 2px 4px 0px rgba(120, 109, 73, 0.33);
                        border-radius: 6px;
                        text-align: center;
                        line-height: 36px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #000;
                        cursor: pointer;
                    }
                }
            }
        }

        .time {
            height: 500px;
            margin-top: -161px;
            padding-top: 182px;
            background: #f9f9f9;

            .title {
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                // line-height: 42px;
                letter-spacing: 2px;
                text-align: center;
                // margin-bottom: 22px;
                position: relative;
                width: fit-content;
                margin: 0 auto 22px;
                display: flex;
                align-items: flex-end;
                span{
                    // position: absolute;
                    // bottom: 0;
                    // left: 0;
                }
                .bor {
                    width: 60px;
                    height: 16px;
                    background: #E4200C;
                    opacity: .5;
                    position: absolute;
                    // left: calc(50% - 30px);
                    // margin-top: -21px;
                }
            }

            .time-img {
                padding: 0 10%;
                text-align: center;

                >img {
                    max-width: 100%;
                }
            }
        }

        .apply {
            display: flex;
            flex-wrap: wrap;
            text-align: center;

            .apply-content {
                display: flex;
                flex-wrap: wrap;

                .apply-item {
                    width: 46%;
                    margin: 2%;
                    background: #dddddd;
                    padding: 10px 0 20px 0;

                    >img {
                        width: 140px;
                        height: 87.5px;
                    }

                    >div {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        margin: 10px 0 20px 0;
                    }

                    .apply-yes {
                        display: block;
                        margin: auto;
                        margin-top: 12px;
                        width: 120px;
                        height: 42px;
                        background: #E4200C;
                        border-radius: 8px;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        border: none;
                    }

                    .apply-no {
                        display: block;
                        margin: auto;
                        margin-top: 12px;
                        width: 120px;
                        height: 42px;
                        background: #999999;
                        border-radius: 8px;
                        border: none;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #dddddd;
                    }
                }
            }
        }

        .guide {
            >img {
                width: 100%;
                height: 893px;
            }

            .guide-center {
                padding-left: 8.1%;
                position: relative;
            }

            .guide-body {

                .guide-title {
                    >img {
                        width: 8px;
                        height: 40px;
                        margin-right: 20px;
                    }

                    text-shadow: 0px 0px #000;
                    font-size: 18px;
                    font-family: PingFangSC-Medium,
                    PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 93px;
                }

                .des-con {
                    .list {
                        padding: 0 37px;
                    }
                }
            }
        }

        .review {
            padding-left: 8.1%;

            .review-body {

                .review-title {
                    >img {
                        width: 8px;
                        height: 40px;
                        margin-right: 20px;
                    }

                    text-shadow: 0px 0px #000;
                    font-size: 18px;
                    font-family: PingFangSC-Medium,
                    PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 93px;
                }

                .des-con {
                    .list {
                        padding: 0 37px;
                    }
                }
            }
        }

        .awards {
            padding-bottom: 100px;

            .awards-content {

                .awards-title {
                    padding-left: 8.1%;

                    >img {
                        width: 8px;
                        height: 40px;
                        margin-right: 20px;
                    }

                    text-shadow: 0px 0px #000;
                    font-size: 18px;
                    font-family: PingFangSC-Medium,
                    PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 93px;
                }

                .awards-body {
                    display: flex;
                    padding: 0 15.7%;
                    justify-content: center;


                    .awards-item {

                        .awards-back {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 80px;
                            height: 80px;
                            margin: 0 auto;

                            >img {
                                width: 50px;
                                height: 50px;
                            }
                        }

                        .rank {
                            font-size: 14px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 600;
                            color: #333333;
                            margin: 10px 0;
                            text-align: center;
                        }

                        .awards-intro {
                            text-align: center;
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                            line-height: 26px;
                        }

                        &:nth-last-child(1) {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}