@media screen and (min-width: 1440px) {
    .news {
        width: 1200px;
        margin: 50px auto 0;

        .news-item {
            margin-bottom: 30px;
            display: flex;
            width: 100%;
            height: 208px;
            background: #fff;
            box-shadow: 0px 2px 4px 2px #EEF0F1;
            border-radius: 10px;
            padding: 20px;
            cursor: pointer;

            >img {
                width: 300px;
                height: 168px;
            }

            .news-body {
                margin-left: 30px;
                padding: 10px 10px 0 0;
                width: calc(100% - 330px);

                .news-title {
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 25px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                }

                .news-content {
                    margin: 14px 0 22px 0;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 4;
                }

                .news-other {
                    display: flex;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 17px;
                    display: flex;
                    justify-content: space-between;

                    .author {
                        margin-right: 20px;
                        // width: 120px;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                        // display: -webkit-box;
                        // -webkit-box-orient: vertical;
                        // -webkit-line-clamp: 1;
                    }
                }
            }
        }

        .ant-pagination {
            margin: 80px auto 100px;
            width: fit-content;

            .ant-pagination-item-active a {
                color: #fff;
                background: #31A25E;
            }
        }
    }

    .news-h5 {
        display: none;
    }
}

@media screen and (min-width: 750px) and (max-width: 1439px) {
    .news {
        padding: 0 8.3%;
        width: 100%;
        margin: 50px auto 0;

        .news-item {
            margin-bottom: 30px;
            display: flex;
            width: 100%;
            height: 208px;
            background: #fff;
            box-shadow: 0px 2px 4px 2px #EEF0F1;
            border-radius: 10px;
            padding: 20px;
            cursor: pointer;

            >img {
                width: 300px;
                height: 168px;
            }

            .news-body {
                margin-left: 30px;
                padding: 10px 10px 0 0;
                width: calc(100% - 330px);

                .news-title {
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 25px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                }

                .news-content {
                    margin: 14px 0 22px 0;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 4;
                }

                .news-other {
                    display: flex;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 17px;
                    display: flex;
                    justify-content: space-between;

                    .author {
                        margin-right: 20px;
                        width: 120px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                    }
                }
            }
        }

        .ant-pagination {
            margin: 80px auto 100px;
            width: fit-content;

            .ant-pagination-item-active a {
                color: #fff;
                background: #31A25E;
            }
        }
    }

    .news-h5 {
        display: none;
    }
}

@media screen and (min-width: 360px) and (max-width: 750px) {
    .news {
        display: none;
    }

    .news-h5 {
        display: block;
        padding: 0 14px;
        margin-top: 44px;

        .section-title {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 52px;
            text-align: center;
            position: fixed;
            background: #fff;
            width: calc(100% - 28px);
        }

        .news-body {
            margin-top: 52px;

            .news-item {
                border-top: 1px solid #f1f1f1;
                padding: 16px 0 14px 0;
                cursor: pointer;

                .news-top {
                    height: 56px;
                    display: flex;
                    justify-content: space-between;

                    .news-left {
                        margin-right: 16px;

                        .news-title {
                            font-size: 14px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                            line-height: 20px;
                            margin-bottom: 6px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            overflow: hidden;
                        }

                        .news-content {
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                            line-height: 17px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                        }
                    }

                    >img {
                        width: 100px;
                        height: 56px;
                    }
                }

                .news-bottom {
                    display: flex;
                    justify-content: space-between;
                    font-size: 10px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 14px;
                    margin-top: 6px;
                }
            }
            .author {
                margin-right: 20px;
                margin-top: 10px;
                width: 120px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
            }
            .time{
                margin-top: 10px;
               
            }
        }
    }
}