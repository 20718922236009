
@media screen and (min-width: 751px) {
    .isdel-dialog{
        .ant-modal-content{
            width:440px !important;
            .ant-modal-close{
                color: #FFFFFF;
                // display: block;
            }
            .ant-modal-body{
                padding: 50px 0 40px 0 !important;
                text-align: left;
                border-radius: 20px !important;

                .ask{
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    text-align: center;
                }
                .btn-box{
                    width: fit-content;
                    margin: 50px auto 0;
                    display: flex;
                    .ant-form-item{
                        margin-bottom: 0;
                    }
                    .ant-form-item-control-input-content{
                        display: flex;
                        // justify-content: space-between;
                    }
                    .ok, .cancel{
                        width: 140px;
                        height: 46px;
                        border-radius: 6px;
                        font-size: 16px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        margin-top: 0;
                    }
                    .ok{
                        background: #31A25E;
                        color: #FFFFFF;
                        margin-right: 40px;
                    }
                    .cancel{
                        background: #DDDDDD;
                        color: #999999;
                        border-color: #DDDDDD;
                        line-height: 46px;
                        text-align: center;
                        cursor: pointer;
                    }
                }
                .btn-box-h5{
                    display: none;
                }
            }
        }
        
    }
}


@media screen and (min-width: 360px) and (max-width: 750px){
    .isdel-dialog{
        .ant-modal-content{
            width: 295px !important;
            margin: 0 auto;
            .ant-modal-close{
                color: #FFFFFF;
                // display: block;
            }
            .ant-modal-body{
                padding: 50px 0 0 0 !important;
                text-align: left;
                border-radius: 20px !important;

                .ask{
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    text-align: center;
                }
                .btn-box{
                    display: none;
                }
                .btn-box-h5{
                    width: 100%;
                    height: 49px;
                    display: flex;
                    border-top: 1px solid #DDDDDD;
                    align-items: center;
                    margin-top: 40px;
                    >div{
                        height: 100%;
                        width: 50%;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        line-height: 48px;
                        cursor: pointer;
                        text-align: center;
                    }
                    >div:nth-of-type(2){
                        color: #44A25A;
                        border-left: 1px solid #DDDDDD;
                    }
                }
            }
        }
        
    }
}