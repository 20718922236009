.information {
    background-color: #FAFAFB;
    padding: 70px 0 28px;

    .info-container {
        width: fit-content;
        margin: 0 auto;
        display: flex;
    }

    .tablist {
        margin-right: 40px;

        .item {
            height: 33px;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 33px;
            padding-left: 30px;
            border-left: 10px solid #4BAD6B;
            display: flex;
            align-items: center;

            img {
                margin-right: 18px;
            }
        }
    }

    .container {
        width: 1027px;
        min-height: 675px;
        background: #FFFFFF;
        .ant-tabs-nav{
            margin-bottom: 30px;
            .ant-tabs-tab {
                font-size: 24px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
            }
        }
        
        .infocen {
            position: relative;

            .num {
                position: absolute;
                right: -6px;
                top: 0;
                width: 18px;
                height: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: url('../../assets/images/circle.png') no-repeat;
                font-size: 12px;
                color: #fff;
            }
        }

        .info-list {
            .item {
                display: flex;
                align-items: center;
                margin-bottom: 37px;
                cursor: pointer;
                .avatar{
                    position: relative;
                    img{
                        width: 50px;
                        height: 50px;
                    }
                    .circle{
                        width: 12px;
                        height: 12px;
                        background: #FD080B;
                        border-radius: 50%;
                        position: absolute;
                    }
                }
                .info-inner {
                    margin-left: 16px;

                    .title {
                        font-size: 18px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        display: flex;
                        align-items: center;

                        .date {
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #666666;
                            margin-left: 20px;
                        }
                    }

                    .inner {
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        margin-top: 10px;
                    }
                }
            }
            .ant-pagination{
                display: flex;
                justify-content: center;
            }
            // .noinfo, .logintocheck{
            //     display: block;
            //     margin: 0 auto;
            // }
            
        }
        .noinfo{
                margin: 176px auto 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                >div{
                    margin-top: 33px;
                }
            }
            .logintocheck{
                margin-top: 33px;
                cursor: pointer;
            }
    }
}