//弹框
.sign-up-dialog{
    .ant-modal-close{
        display: none;
    }
    .ant-modal-content{
        width: 634px;
        border-radius: 20px;
        .ant-modal-body{
            padding: 70px 87px !important;
            text-align: center;
            position: relative;
            .del{
                position: absolute;
                right: 26px;
                top: 26px;
                cursor: pointer;
            }
            .human{
                width: 349px;
                height: 220px;
            }
            .title{
                font-size: 24px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                margin-top: 32px;
            }
            .title2{
                text-align: left;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                margin-top: 20px;
            }
            .ant-btn-primary{
                width: 460px;
                height: 60px;
                background: #31A25E;
                border-radius: 6px;
                font-size: 24px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                letter-spacing: 2px;
                margin-top: 50px;
            }
        }
    }
    
}


@media screen and (min-width: 360px) and (max-width: 750px){
    .sign-up-dialog-h5{
        display: block;
        .ant-modal-content{
            width: 315px !important;
            .ant-modal-body{
                border-radius: 10PX;
                padding: 0 !important;
                .title{
                    padding: 50px 29px 40px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
                .btns{
                    width: 100%;
                    height: 49px;
                    display: flex;
                    border-top: 1px solid #DDDDDD;
                    align-items: center;
                    >div{
                        height: 100%;
                        width: 50%;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        line-height: 48px;
                        cursor: pointer;
                    }
                    >div:nth-of-type(2){
                        color: #44A25A;
                        border-left: 1px solid #DDDDDD;
                    }
                }
            }
        }
    }
}