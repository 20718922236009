@media screen and (min-width: 1440px) {
    .judges-content {
        padding-top: 50px;
        background: url('../../assets/images/backJudges.png');
        background-size: cover;
        .ant-dropdown-trigger {
            border: 1px solid #dddddd;
            border-radius: 4px;
            padding: 8px 20px;
        }
    
        .judges-body {
            padding-top: 65px;
            width: 1100px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
    
            #add-node {
                >h2 {
                    display: inline;
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #222222;
                }
    
                >h3 {
                    margin-top: 20px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #222222;
                }
    
                >p {
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                }
    
                #close {
                    float: right;
                }
                > p > * {
                    font-family: PingFangSC-Regular, PingFang SC !important;
                }
            }
    
            .item {
                cursor: pointer;
                // margin: 2% 1%;
                margin: 0 1% 36px;
                width: 18%;
                text-align: center;
                background: #f6f6f6;
                // overflow: hidden;
                position: relative;
                padding-bottom: 10px;
    
                >img.avatar {
                    width: 198px;
                    height: 198px;
                }
    
                .name {
                    margin: .625rem;
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #222222;
                }
    
                .job {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 4;
                    overflow: hidden;
                    color: #222;
                    font-size: .9375rem;
                    padding: 0 .375rem;
                    font-weight: 400;
    
                }
    
                .sanjiao {
                    position: absolute;
                    bottom: -36px;
                    left: 50%;
                    margin-left: -14px;
                    display: none;
                }
    
                .sanjiao-active {
                    display: block;
                }
            }
    
        }
    }
    
    #add-node {
        width: 100%;
        min-width: 1100px;
        padding: 20px;
        margin-bottom: 20px;
        background: #e2e2e2;
        overflow: hidden;
        border-radius: 10px;
    }
}
@media screen and (min-width: 751px) and (max-width: 1439px) {
    .judges-content {
        padding: 50px 11.7% 0 11.7%;
        background: url('../../assets/images/backJudges.png');
    
        .ant-dropdown-trigger {
            border: 1px solid #dddddd;
            border-radius: 4px;
            padding: 8px 20px;
        }
    
        .judges-body {
            padding-top: 65px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
    
            #add-node {
                >h2 {
                    display: inline;
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #222222;
                }
    
                >h3 {
                    margin-top: 20px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #222222;
                }
    
                >p {
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                }
    
                #close {
                    float: right;
                }
                > p > * {
                    font-family: PingFangSC-Regular, PingFang SC !important;
                }
            }
    
            .item {
                cursor: pointer;
                // margin: 2% 1%;
                margin: 0 1% 36px;
                width: 18%;
                text-align: center;
                background: #f6f6f6;
                // overflow: hidden;
                position: relative;
                padding-bottom: 10px;
    
                >img.avatar {
                    width: 100%;
                }
    
                .name {
                    margin: .625rem;
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #222222;
                }
    
                .job {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 4;
                    overflow: hidden;
                    color: #222;
                    font-size: .9375rem;
                    padding: 0 .375rem;
                    font-weight: 400;
    
                }
    
                .sanjiao {
                    position: absolute;
                    bottom: -36px;
                    left: 50%;
                    margin-left: -14px;
                    display: none;
                }
    
                .sanjiao-active {
                    display: block;
                }
            }
    
        }
    }
    
    #add-node {
        width: 100%;
        padding: 20px;
        margin-bottom: 20px;
        background: #e2e2e2;
        overflow: hidden;
        border-radius: 10px;
    }
}

@media screen and (min-width: 360px) and (max-width: 750px) {
    .judges-content {
        margin-top: 14px;
        padding: 50px 4.7% 0 4.7%;
        background: url('../../assets/images/backJudges.png');
    
        .ant-dropdown-trigger {
            border: 1px solid #dddddd;
            border-radius: 4px;
            padding: 8px 20px;
        }
    
        .judges-body {
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
    
            #add-node {
                >h2 {
                    display: inline;
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 600;
                    color: #222222;
                }
    
                >h3 {
                    margin-top: 20px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #222222;
                }
    
                >p {
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                }
    
                #close {
                    float: right;
                }
                > p > * {
                    font-family: PingFangSC-Regular, PingFang SC !important;
                }
            }
    
            .item {
                cursor: pointer;
                // margin: 2% 1%;
                margin: 0 1% 26px;
                width: 47%;
                text-align: center;
                // overflow: hidden;
                position: relative;
                padding-bottom: 10px;
    
                >img.avatar {
                    width: 100%;
                }
    
                .name {
                    margin: .625rem;
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 600;
                    color: #222222;
                }
    
                .job {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 4;
                    overflow: hidden;
                    color: #222;
                    font-size: .9375rem;
                    padding: 0 .375rem;
                    font-weight: 400;
    
                }
    
                .sanjiao {
                    position: absolute;
                    bottom: -36px;
                    left: 50%;
                    margin-left: -14px;
                    display: none;
                    margin-bottom: 10px;
                }
    
                .sanjiao-active {
                    display: block;
                }
            }
    
        }
    }
    
    #add-node {
        width: 100%;
        padding: 20px;
        margin-bottom: 20px;
        background: #e2e2e2;
        overflow: hidden;
        border-radius: 10px;
    }
}
