.swiper-box{
    width: 100%;
    height: 716px;
    background: url('../../assets/images/swi-bg.png') no-repeat;
    background-size: 100% 100%;
    padding: 72px 0 62px 0;
    .intro{
      width: 980px;
      height: 112px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
      margin: 50px auto 0;
    }
    .content{
      width: 990px;
      margin: 0 auto;
      position: relative;
      .swiper-container {
        width: 990px;
        height: 420px;
      }
      .swiper-slide {
        border-radius: 20px;
        font-size: 18px;
        // background: red;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-transition: transform 1.0s;
        -moz-transition: transform 1.0s;
        -ms-transition: transform 1.0s;
        -o-transition: transform 1.0s;
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
        // border-radius: 20px;
        // overflow: hidden;
      }
      .swiper-slide-active,.swiper-slide-duplicate-active {
        border-radius: 20px;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      .swiper-button-prev, .swiper-button-next{
        color: #000;
      }
      .swiper-button-prev{
        left: -65px !important;
      }
      .swiper-button-next{
        right: -65px !important;
      }
      
  }
}



  
 