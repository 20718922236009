.footer-container{
    // margin-top: 120px;
    width: 100%;
    height: 260px;
    // padding-bottom: 20px;
    background: #F9F9F9;
    padding: 0 7%;
    .content{
        display: flex;
        justify-content: center;
        .conleft{
            display: flex;
            // align-items: center;
            margin-top: 60px;
            img{
                width: 71px;
                height: 64px;
            }
        }
        .conright{
            padding: 60px 0 60px 2%; 
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .list{
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                .item{
                    width: 50%;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    display: flex;
                    img{
                        margin-right: 16px;
                    }
                }
                .item:nth-last-of-type(1), .item:nth-last-of-type(2){
                    margin-top: 24px;
                }
                .privacy{
                    cursor: pointer;
                }
            }
            
        }
        
    }
    .btom{
        display: flex;
        justify-content: center;
        flex-direction: column;
        a{
            display: block;
            color: #333333;
        }
        >div:nth-last-of-type(1){
            margin-left: 80px;
            text-align: center;
        }
        img{
            margin-right: 5px;
        }
    }
}

@media screen and (min-width: 360px) and (max-width: 750px){
    .footer-container{
        padding: 15px;
        height: fit-content;
        .content{
            display: flex;
            justify-content: center;
            align-items: center;
            .conleft{
                display: flex;
                // align-items: center;
                margin-top: 0;
                img{
                    width: 50px;
                    height: 44px;
                }
            }
            .conright{
                padding: 0 0 0 15px; 
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .list{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    .item{
                        width: 100%;
                        font-size: 10px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        display: block;
                        img{
                            margin-right: 16px;
                        }
                    }
                    .item:nth-last-of-type(1), .item:nth-last-of-type(2){
                        margin-top: 15px;
                    }
                    .privacy{
                        display: none;
                    }
                }
                
            }
        }
        .btom{
            >div:nth-last-of-type(1){
                margin: 20px 0 0 0;
            }
            a{
                font-size: 10px;
            }
        }
    }
}