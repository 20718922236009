.myteam {
    width: 1080px;
    margin: 0 auto;
    padding: 72px 0 80px;

    .maintitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 62px;

        .title {
            display: flex;
            align-items: center;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;

            .name {}
        }
        .btns{
            display: flex;
            .add {
                width: 176px;
                height: 47px;
                background: #333333;
                border-radius: 23px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                cursor: pointer;
    
                img {
                    margin-right: 12px;
                }
            }
            .add:nth-last-of-type(1){
                margin-left: 10px;
            }
        }
        
    }

    .ant-table {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;

        .ant-table-thead {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
        }

        .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
        .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
        .ant-table-thead>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
        .ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
        .ant-table-thead>tr>th {
            background: #fff;
        }

        .operate {
            span {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #4BAD6B;
                margin-right: 38px;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .noright {
            color: #999999;
        }
    }

    .ant-table-pagination-right {
        justify-content: center;
    }

}