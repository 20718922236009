.add-member {
    width: 629px;
    // min-height: 458px;

    .modal-body {
        font-size: 18px;
    }

    .ant-modal-content {
        width: 629px !important;
        // min-height: 458px;
        border-radius: 8px !important;

        .ant-modal-body {
            width: 629px;
            // min-height: 458px;
            padding: 28px 70px 30px !important;
            display: flex;
            flex-direction: column;

            .title {
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                margin-top: 0 !important;
                margin-bottom: 30px;
            }

            // .ant-form{
            //     flex: 1;
            //     display: flex;
            //     flex-direction: column;
            //     justify-content: space-between;
            // }
            .ant-select,
            .ant-input {
                width: 260px;
            }

            .ant-form-item {
                margin-bottom: 15px;
                .ant-form-item-label{
                    width: 140px;
                }
                .ant-form-item-control{
                    flex-flow: 1;
                }
                .ant-form-item-control-input-content{
                    display: flex;
                }
            }
            .ant-form-item:nth-last-of-type(1){
                margin-bottom: 0;
                .ant-form-item-control-input-content{
                    display: block;
                }
            }
            .ant-form-item-explain.ant-form-item-explain-error{
                // text-align: left;
                width: 260px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
            .btns {
                display: flex;
                justify-content: center;
                margin-top: 15px;
                .btn {
                    width: 120px;
                    height: 40px;
                    border-radius: 6px;
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    text-align: center;
                    cursor: pointer;
                    margin: 0;
                    border: none;
                }

                .btn::after {
                    display: none;
                }

                .btn:nth-of-type(1) {
                    color: #FFFFFF;
                    background: #333333;
                    margin-right: 50px;
                }

                .btn:nth-last-child(1) {
                    color: #999999;
                    background: #DDDDDD;
                }
            }

            .tips {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #4BAD6B;
                line-height: 18px;
                margin-top: 20px;
                text-align: left;
            }
        }
    }

    .ant-modal-close {
        display: block !important;
    }

}