//登录
.content-box {
    width: 100%;
    height: -webkit-fill-available;
    background-color: #F9F9F9;
    display: flex;
    align-items: center;
    min-height: 1024px;
    // padding-top: 210px;

    .login {
        .backlogin {
            display: none;
        }

        width: 600px;
        padding: 120px 70px 50px;
        background: #FFFFFF;
        border-radius: 20px;
        margin: 0 auto;
        position: relative;

        .title {
            margin-bottom: 40px;
        }

        .logo {
            width: 109px;
            height: 109px;
            border-radius: 50%;
            background-color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -54px;
            left: 50%;
            margin-left: -54px;

            img {
                width: 71px;
                height: 64px;
            }
        }

        .logo-h5 {
            display: none;
        }

        .ant-tabs-nav .ant-tabs-nav-list {
            width: 100%;

            .ant-tabs-tab {
                flex: 1;
                margin: 0;
                font-size: 24px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;

                .ant-tabs-tab-btn {
                    margin: 0 auto;
                }
            }

            .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                color: #31A25E;
            }

            .ant-tabs-ink-bar {
                background-color: #31A25E;
            }
        }

        .ant-tabs-nav::before {
            border-bottom: 2px solid #F1F1F1 !important;
        }

        .ant-form {
            padding: 0 2px;
        }

        .ant-input-affix-wrapper {
            // width: 460px;
            height: 60px;
            background: #FFFFFF;
            border-radius: 6px;
            border: 1px solid #DDDDDD;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            padding-left: 18px;
            padding-right: 18px;

            .ant-input-prefix {
                margin-right: 18px;
            }
        }

        .user {
            margin-top: 40px;
            //     margin: 40px 0 24px 0;
        }

        .pwd {
            margin-bottom: 46px;
        }

        .cancel {
            width: 21px;
            height: 21px;
            position: absolute;
            right: 32px;
            top: 32px;
            cursor: pointer;
        }

        .ant-btn-primary {
            width: 100%;
            height: 60px;
            background: #31A25E;
            border-radius: 6px;
            border: 0;
            box-shadow: 0;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            margin-bottom: 0;
        }

        .back {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #31A25E;
            line-height: 20px;
            text-align: center;
            margin: 20px 0;
            cursor: pointer;
            display: block;
        }

        .forgetpwd {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #31A25E;
            line-height: 20px;
            text-align: right;
            margin: 14px 0;
            cursor: pointer;
            display: block;
        }

        .register {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #31A25E;
            text-align: center;
            cursor: pointer;
            display: block;
        }

        .sendcode {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #31A25E;
            cursor: pointer;
            border: none;
        }
    }
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-operations-hidden {
    position: relative;
}

//注册
.register-box {
    .title {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #31A25E;
        text-align: center;
    }

    // .code{
    //     margin-bottom: 24px;
    // }
    .del {
        width: 14px;
        height: 14px;
        cursor: pointer;
    }

    // .pwd{
    //     margin-bottom: 50px;
    // }
    .ant-checkbox-inner {
        width: 16px !important;
        height: 16px !important;
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        border-radius: 50%;
    }

    .ant-checkbox-inner::after,
    .ant-checkbox-checked::after {
        border: 0;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #31A25E;
        border-color: #31A25E;
    }

    .ant-checkbox-wrapper {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-top: 16px;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus+.ant-checkbox-inner {
        border-color: #DDDDDD;
    }

    .haveid {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #31A25E;
        text-align: center;
        margin-top: 40px;
        cursor: pointer;
        display: block;
    }

    .registertxt {
        color: #31A25E;
    }
}

//重置密码
.reset-box {
    .check {
        margin-right: 17px;
        cursor: pointer;
    }
}

//去除input聚焦阴影
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 0 !important;
}

.ant-btn-primary,
.ant-btn-primary:focus,
.ant-btn-primary:hover {
    border-color: #31A25E;
}

//弹框
.ant-modal {
    .ant-modal-close {
        display: none;
    }

    .ant-modal-content {
        width: 634px;
        border-radius: 20px;
    }

    .ant-modal-body {
        padding: 48px 50px 70px !important;
        text-align: center;

        .human {
            width: 349px;
            height: 220px;
        }

        .shanchu {
            position: absolute;
            top: 29px;
            right: 26px;
        }

        .title {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-top: 32px;
        }

        .title2 {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            margin-top: 20px;
        }

        .ant-btn-primary {
            width: 460px;
            height: 60px;
            background: #31A25E;
            border-radius: 6px;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            margin-top: 50px;
        }
    }
}

// .modal-tip {
//     .ant-modal-body .ant-btn-primary {
//         line-height: 60px;
//         padding: 0;
//     }
// }

// .modal-body{
//     font-size: 20px ;
// }
// .btns{
//     display: flex;
//     justify-content: space-around;
//     .btn{
//         width: 100px !important;
//     }
// }
@media screen and (min-width: 360px) and (max-width: 750px) {

    .login {
        .backlogin {
            display: block;
        }

        .ant-tabs-tab-btn {
            font-size: 20px;
        }

        .ant-btn-primary {
            height: 42px !important;

            >span {
                font-size: 16px;
                display: block;
            }
        }
    }

    .register-box {
        .title {
            font-size: 20px;
        }
    }

    .haveid {
        font-size: 14px !important;
    }

    .my-drawer {
        position: relative;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        .drawer-list {
            padding: 20px 20px 0;

            .xieyi-title {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                text-align: center;
                position: relative;

                .chahao {
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }

        }
    }

    .my-drawer .am-drawer-sidebar {
        background-color: #fff;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        border-radius: 20px 20px 0px 0px;
        height: 70%;
    }

    .content-box {
        background: #fff;
        min-height: 100%;

        .login {
            padding: 0 10%;
            max-width: 630px;
            margin-top: 44px;

            .logo {
                display: none;
            }

            .logo-h5 {
                display: block;
                width: 100%;
                height: 88px;
                line-height: 88px;
                text-align: center;

            }

            .ant-tabs-tab-btn {
                font-size: 18px;
            }

            .ant-input-affix-wrapper {
                height: 42px;
            }

            .cancel {
                display: none;
            }
        }
    }

    .xieyi-title {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        text-align: center;
        position: relative;

        .chahao {
            float: right;
            margin-top: 6px;
        }
    }

    .drawer-content-box {
        padding: 0 20px;
    }

    // .ant-drawer-content{
    //     background-color: #fff;
    //     border-radius: 20px 20px 0px 0px;
    // }
}


.move-modal-tip {
    width: 295px !important;

    .title {
        font-size: 16px !important;
        margin-top: 0 !important;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
    }

    .title2 {
        font-size: 12px !important;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        text-align: left;
    }

    .h5-btn {
        width: 200px !important;
        height: 36px !important;
        background: #179FEF !important;
        margin-top: 18px !important;
        font-size: 16px !important;
    }

    .ant-modal-body {
        padding: 30px 18px !important;
    }

    .ant-modal-content {
        width: 100%;
    }
}