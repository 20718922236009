.mess-sign {
    width: 1200px;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px 4px #EEF0F1;
    border-radius: 30px;
    margin-top: 40px;
    padding: 70px 130px;
    margin-bottom: 120px;

    .title {
        width: 180px;
        font-size: 30px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        position: relative;
        margin-bottom: 70px;

        .bor {
            width: 100%;
            height: 21px;
            background: #31A25E;
            opacity: 0.7;

        }

        span {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
        width: 100%;

        .ant-tabs-tab {
            width: 50%;
            margin: 0;
        }

        .ant-tabs-tab-btn {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
        }

        .ant-tabs-ink-bar {
            height: 4px;
            background-color: #31A25E;
        }
    }

    .ant-tabs-nav::before {
        border-bottom: 4px solid #F1F1F1 !important;
    }

    .ant-tabs-top>.ant-tabs-nav,
    .ant-tabs-bottom>.ant-tabs-nav,
    .ant-tabs-top>div>.ant-tabs-nav,
    .ant-tabs-bottom>div>.ant-tabs-nav {
        margin-bottom: 50px !important;
    }

    .ant-tabs>.ant-tabs-nav .ant-tabs-nav-operations-hidden,
    .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-operations-hidden {
        position: relative;
    }

    .messcont {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-right: 60px;

        .tel {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;

            // padding-right: 20px;
            .phone {
                width: 600px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 25px;
                letter-spacing: 1px;
            }

            .xiugai {
                position: absolute;
                top: 0;
                right: -32px;
                cursor: pointer;
            }
        }

        .lable,
        .ant-form-item-label>label,
        .up-lable {
            margin-right: 20px;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }

        .lable {
            padding-right: 8px;
        }

        .inp {
            display: flex;
            justify-content: flex-end;

            .ant-input {
                width: 600px;
                height: 50px;
                // background: #FFFFFF;
                // border-radius: 4px;
                // border: 1px solid #DDDDDD;
                font-size: 18px;
                // color: #DDDDDD;
                // border-color: #DDDDDD !important;
            }

            .ant-input:focus,
            .ant-input-focused {
                // border-color: #DDDDDD !important;
                // box-shadow: 0 0 0 0 !important;
                // box-shadow:0 0 0 2px rgba(35, 47, 63, 0.2);
                // border-color: #333;
            }

            .ant-form-item {
                text-align: right;
                margin: 0;
                display: flex;
                align-items: center;
            }

            .ant-form-item-label>label {
                display: block;
                width: 253px;
                height: fit-content;
            }

            .ant-form-item-label {
                white-space: normal;
            }

            .ant-form-item-explain.ant-form-item-explain-error {
                text-align: left;
            }

            .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
                display: none;
            }

            .haveinfo {
                text-align: left;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 25px;
                letter-spacing: 1px;
            }

            .name,
            .email,
            .age,
            .sex,
            .info,
            .up-lable,
            .code {
                margin-top: 30px;
            }

            .name-en,
            .info-en {
                margin-top: 14px;
            }

            .name-en {
                .ant-form-item-explain.ant-form-item-explain-error {
                    text-align: left;
                    display: flex;
                    justify-content: flex-end;

                    >div {
                        width: 600px;
                    }
                }
            }

            .email-box {
                .cont {
                    display: flex;
                    align-items: center;
                    margin-top: 30px;
                    justify-content: space-between;
                    position: relative;

                    .email {
                        margin-top: 0;

                        .ant-input {
                            width: 437px;
                        }

                        .ant-form-item-control-input-content {
                            align-items: center;
                        }
                    }

                    .send-code {
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #31A25E;
                        letter-spacing: 1px;
                        text-decoration: underline;
                        // margin-left: 30px;
                        // cursor: pointer;
                        height: fit-content;
                        padding: 0;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        margin-top: -18px;
                    }
                }

                .tips {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #31A25E;
                    line-height: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    margin-top: 14px;

                    >div {
                        width: 608px;
                    }
                }

                .ant-btn,
                .ant-btn:hover,
                .ant-btn:focus,
                .ant-btn:active {
                    background-color: #FFFFFF;
                    border-color: #FFFFFF;
                    box-shadow: none;
                }

                .ant-btn::after {
                    display: none;
                }

                .haveinfo {
                    padding-left: 2px;
                }

                .ant-form-item-control {
                    margin-left: 4px;
                }
            }

            .code {
                .ant-form-item-label>label::after {
                    display: none;
                }
            }

            .ant-form-item-control-input-content {
                display: flex;
                justify-content: flex-end;

                .haveinfo {
                    width: 600px;
                }
            }

            .sex {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .ant-radio-group {
                    width: 600px;

                    .ant-radio-wrapper {
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 25px;
                        letter-spacing: 1px;
                    }

                    .ant-radio-checked .ant-radio-inner {
                        border-color: #31A25E;
                    }

                    .ant-radio-inner::after {
                        background-color: #31A25E;
                    }
                }
            }

            .info {
                .ant-form-item-label {
                    text-align: left;
                }

                .ant-form-item-label>label::after {
                    display: none;
                }

                .ant-form-item-label>label {
                    width: fit-content;
                }
            }

            .info-en {
                .ant-form-item-label {
                    margin-top: -20px;
                }
            }

            .ant-form-item {

                //    display: flex;
                //    align-items: center;
                .ant-input {
                    //    border-color: #FFFFFF;
                    //    padding: 4px 0;
                }

                .changemess {
                    border-color: #DDDDDD;
                    padding: 4px 11px;
                }
            }

            .update {
                display: flex;
                justify-content: flex-end;
            }

            .upcont {
                width: 600px;

                .up-box {
                    margin: 30px 0 14px 0;
                    display: flex;

                    // .up-cn, .up-en{
                    //      width: 147px;
                    //      height: 140px;
                    //      background: #FFFFFF;
                    //      box-shadow: 0px 4px 6px 4px #EEF0F1;
                    //      border-radius: 6px;
                    //      font-size: 14px;
                    //      font-family: PingFangSC-Regular, PingFang SC;
                    //      font-weight: 400;
                    //      color: #999999;
                    //      display: flex;
                    //      align-items: center;
                    //      justify-content: center;
                    //      .cont{
                    //          text-align: center;
                    //          .txt{
                    //              margin-top: 20px;
                    //          }
                    //      }
                    // }
                    .upload-en {
                        margin-left: 100px;
                    }

                    // 上传
                    .ant-upload.ant-upload-select {
                        width: 145px;
                        height: 140px;
                        margin-right: 60px;
                        background: #FFFFFF;
                        box-shadow: 0px 4px 6px 4px #EEF0F1;
                        border-radius: 6px;
                        border: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        >span.ant-upload {
                            text-align: center;
                        }
                    }

                    .ant-upload.ant-upload-select+.ant-upload-list {
                        width: 150px;
                    }

                    .ant-space-item .ant-form-item-explain.ant-form-item-explain-error {
                        margin-top: 10px;
                    }

                    .ant-space-align-center {
                        align-items: flex-start;
                    }

                    .fileicon {
                        height: 100%;
                        width: 140px;
                        padding: 10px;
                        position: relative;

                        .anticon.anticon-down {
                            position: absolute;
                            top: 20px;
                            right: 20px;
                        }

                        >img {
                            margin-top: 30px;
                        }

                        >span.text {
                            margin-top: 10px;
                            width: 100%;
                            display: inline-block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                    }
                }

                .hint {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #31A25E;
                    line-height: 20px;
                }

                .btn {
                    text-align: left;

                    .savebtn {
                        width: 160px;
                        height: 54px;
                        background: #31A25E;
                        border-radius: 6px;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin: 70px 0 0 110px;
                    }

                    .ant-form-item-control-input-content {
                        justify-content: normal;
                    }
                }

                .ant-form-item {
                    text-align: left;
                }

                .ant-form-item-explain.ant-form-item-explain-error {
                    margin-top: 10px;
                }
            }

        }
    }

    .tab2cont {
        .ant-table-thead {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
        }

        .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
        .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
        .ant-table-thead>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
        .ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
            background: #fff;
        }

        .ant-table {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }

        .ant-table-pagination-right {
            justify-content: center;
        }

        .opera {
            >a, >span {
                text-decoration: underline;
                margin-right: 15px;
                color: #31A25E;
                cursor: pointer;
                font-size: 18px;
                &:nth-last-child(1){
                    margin-right: 0;
                }
            }

            .ant-badge {
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #31A25E;
                text-decoration: underline;
                line-height: 28px;
            }
        }

        .checkDetail {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #31A25E;
        }

        .ant-pagination-item,
        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
            width: 34px;
            height: 34px;
            border-radius: 4px;
            border: 1px solid #DCDCDC;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }

        .ant-pagination-item-active {
            border-color: #31A25E;
            background: #31A25E;
            border-radius: 4px;

            a {
                color: #FFFFFF;
            }
        }

        .ant-table-thead>tr>th {
            background-color: #FFFFFF;
        }

        .empty-ta {
            .ant-table-tbody>tr>td {
                border: none;
            }

            .nodata {
                margin-top: 130px;

                .tip {
                    font-size: 24px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #B5C3CC;
                    margin-top: 50px;
                }
            }
        }

    }

}

.ant-popover-inner {
    background: #000;
    opacity: .5;
    .ant-popover-inner-content{
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 14px;
    }
}

.ant-popover-arrow {
    border-right-color: rgba($color: #000000, $alpha: .5) !important;
    border-bottom-color: rgba($color: #000000, $alpha: .5) !important;
}

@media screen and (min-width: 999px) and (max-width: 1199px) {
    .mess-sign {
        width: 100%;
        box-shadow: none;
        border-radius: 0;
        padding: 38px;

        .tab2cont {

            .ant-table,
            .checkDetail {
                font-size: 17px;
            }

            .ant-table-thead {
                font-size: 17px;
            }

            .empty-ta .nodata {
                margin-top: 80px;

                >img {
                    width: 230px;
                }

                .tip {
                    font-size: 20px;
                }
            }
        }
    }
}
.opera {
    .ant-badge {
        >a{
            font-size: 17px;
        }
    }
}

@media screen and (min-width: 751px) and (max-width: 998px) {
    .mess-sign {

        .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
        .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            font-size: 18px;
        }

        .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab-btn,
        .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab-btn {
            font-size: 18px;
        }

        .tab2cont {

            .ant-table,
            .checkDetail {
                font-size: 15px;
            }

            .ant-table-thead {
                font-size: 15px;
            }

            .empty-ta .nodata {
                margin-top: 50px;

                >img {
                    width: 200px;
                }

                .tip {
                    font-size: 16px;
                }
            }
        }

        .messcont {
            padding-right: 0;

            .tel {
                .lable {
                    width: 30%;
                    font-size: 16px;
                    margin-right: 0;
                    padding-right: 20px;
                    text-align: right;
                }

                .phone {
                    width: 70%;
                }

                .xiugai {
                    right: 20px;
                }
            }

            .inp {
                width: 100%;
                display: block;

                .ant-col.ant-form-item-label {
                    width: 30%;
                }

                .ant-form-item-label>label {
                    width: 100%;
                    padding-right: 20px;
                    font-size: 16px;
                    text-align: right;
                }

                .ant-form-item-control-input-content .haveinfo {
                    width: 100%;
                }

                .email-box .cont {
                    display: block;

                    .ant-form-item-control {
                        margin-left: 0;
                    }
                }

                .sex .lable {
                    font-size: 16px;
                    width: 30%;
                    margin-right: 0;
                    padding-right: 20px;
                    text-align: right;
                }

                .sex .ant-radio-group {
                    width: 70%;
                }

                .up-lable.file-h2 {
                    font-size: 16px;
                }

                .upcont {
                    width: 70%;
                }
            }

            .ant-form-horizontal .ant-form-item-control {
                width: 70%;
            }

            .name-en,
            .info-en {
                .ant-form-item-control {
                    padding-left: 30%;
                }
            }
        }
    }
}
.opera {
    .ant-badge {
        >a{
            font-size: 17px;
        }
    }
}

@media (max-width: 751px) {
    .mess-sign {
        padding: 20px;
        margin-top: 0;

        .title {
            font-size: 20px;
            margin-bottom: 30px;

            .bor {
                height: 12px;
            }
        }

        .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list {
            width: auto;

            .ant-tabs-tab {
                margin-right: 10px;
            }
        }

        .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn,
        .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            font-size: 16px;
        }

        .tab2cont .ant-table-thead {
            font-size: 14px;
        }

        .ant-table-thead>tr>th,
        .ant-table-tbody>tr>td,
        .ant-table tfoot>tr>th,
        .ant-table tfoot>tr>td {
            padding: 5px;
        }
    }
}

@media screen and (min-width: 360px) and (max-width: 750px) {
    .mess-sign-h5 {
        width: 100%;
        min-height: calc(100vh - 224px);
        padding: 0 18px;
        background: #F9F9F9;
        display: block;
        margin-top: 44px;
        .ant-form .ant-form-item .ant-form-item-label {
            flex-basis: 40%;
            margin-right: 0 !important;
            text-align: left;
        }

        .ant-form .ant-form-item .ant-form-item-control {
            flex-basis: 60%;
        }

        .mess-sign-tabs {
            width: 100%;
            height: 38px;
            margin: 20px 0;
            border-radius: 100px;
            border: 1px solid #30A25D;
            display: flex;
            overflow: hidden;

            >div {
                width: 50%;
                line-height: 36px;
                text-align: center;
            }

            .tabactive {
                background-color: #30A25D;
                color: #F9F9F9;
            }
        }

        .h5-mess {
            .suggest {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #30A25D;
                margin-bottom: 10px;
            }

            .excon {
                padding: 15px 17px;
                border-radius: 10px;
                background-color: #fff;
                margin-bottom: 15px;

                .lable {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
            }

            .tel {

                display: flex;
                justify-content: space-between;
                align-items: center;

                .phone {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    padding-right: 3px;
                }
            }

            .namebox {
                .lable {
                    margin-bottom: 15px;
                }

                .ant-form-item-label>label {
                    font-size: 12px;
                }
            }

            .email-box {
                .btnbox {
                    display: flex;
                    justify-content: flex-end;

                    .send-code {
                        // background-color: #30A25D;
                        // color: #fff;
                        margin-bottom: 15px;
                    }
                }
            }

            .tips {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #30A25D;
                line-height: 17px;
                margin-bottom: 10px;
            }

            .otherinfo {
                .lable {
                    margin-bottom: 15px;
                }
            }

            .up-box {
                .lable {
                    margin-bottom: 20px;
                }

                // 上传
                .ant-upload.ant-upload-select {
                    width: 100px;
                    height: 100px;
                    margin-right: 60px;
                    background: #FFFFFF;
                    box-shadow: 0px 4px 6px 4px #EEF0F1;
                    border-radius: 6px;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    >span.ant-upload {
                        text-align: center;
                    }
                }

                .ant-upload.ant-upload-select+.ant-upload-list {
                    width: 100px;
                }

                .ant-space-item .ant-form-item-explain.ant-form-item-explain-error {
                    margin-top: 10px;
                }

                .ant-space-align-center {
                    align-items: flex-start;
                }

                .ant-upload,
                .ant-upload-select,
                .ant-upload-select-file {
                    font-size: 10px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }

                .fileicon {
                    height: 100%;
                    width: 80px;
                    padding: 10px;
                    position: relative;

                    .anticon.anticon-down {
                        position: absolute;
                        top: 20px;
                        right: 20px;
                    }

                    >img {
                        margin-top: 30px;
                    }

                    >span.text {
                        margin-top: 10px;
                        width: 100%;
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                }

                .ant-form-item-label {
                    display: none;
                }

                .ant-form-item-control {
                    flex-basis: 100% !important;
                }

                .ant-space {
                    display: flex;
                    justify-content: space-between;

                    .ant-space-item {
                        margin-right: 0;
                    }

                    .ant-space-item:nth-last-of-type(1) {
                        .ant-upload.ant-upload-select {
                            margin-right: 0;
                        }
                    }
                }
            }

            .btn {
                margin-bottom: 40px !important;

                .savebtn {
                    width: 100%;
                    height: 48px;
                    border-radius: 10px;
                }

                .ant-form-item-label {
                    display: none;
                }

                .ant-form-item-control {
                    flex-basis: 100% !important;
                }
            }

            .ant-form-item-required {

                &::before,
                &::after {
                    display: none !important;
                }
            }

            .ant-form-item {
                margin-bottom: 15px;
            }

            .h5-changemess {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;

                .h5-xiugai {
                    margin-left: 10px;
                }
            }

        }

        .h5-sign {
            .sign-item {
                padding: 15px 15px 25px;
                background-color: #fff;
                margin-bottom: 15px;
                border-radius: 10px;

                .title {
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                }

                .type {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    margin: 10px 0;
                }

                .date {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                }

                .btns {
                    margin-top: 15px;
                    display: flex;
                    // justify-content: space-between;
                    flex-wrap: wrap;

                    .btn {
                        // width: 92px;
                        height: 32px;
                        background: #30A25D;
                        border-radius: 4px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        padding: 4px 11px;
                        margin: 10px 7px 0 0;
                    }
                }
            }

            .nodata {
                text-align: center;
                margin-top: 30%;
                font-size: 14px;

                img {
                    margin-bottom: 10px;
                }
            }
            .ant-pagination{
                text-align: center;
            }
        }

    }
}

// @media (max-width: 575px){
//     .ant-form .ant-form-item .ant-form-item-label{
//         flex-basis: 40%;
//         margin-right: 0 !important;
//     } 
//     .ant-form .ant-form-item .ant-form-item-control{
//         flex-basis: 60%;
//     }
// }