.ant-drawer-body {
    padding: 24px 0;

    .nav-h5 {
        >li {
            >img {
                width: 22px;
                height: 22px;
                
            }
            &:nth-child(5){
                >img{
                    width: 20px;
                    height: 20px;
                }
            }
            &:nth-child(6){
                >img{
                    width: 19px;
                    height: 17px;
                }
            }
        }

        .active {
            background: #ECFCF1;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
        }

        .cavtive {
            background: #ECFCF1;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
        }

        .hr {
            margin-top: 20px;
            border-top: 1px solid #f9f9f9;
        }

        >li {
            >img {
                margin-right: 20px;
            }

            padding-left: 24px;
            height: 42px;
            height: 42px;
            font-size: 16px;
            font-family: PingFangSC-Regular,
            PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 42px;
            letter-spacing: 1px;
            cursor: pointer;

            &:nth-last-child(3) {
                margin-bottom: 20px;
                border-bottom: 1px solid #f9f9f9;
                // padding-bottom: 20px;
                // height: 60px;
            }

            &:nth-last-child(2) {
                margin-top: 20px;
            }
        }

        .nav-h5-list {
            >li {
                height: 42px;
                height: 42px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 42px;
                letter-spacing: 1px;
                padding-left: 65px;
                cursor: pointer;
            }
        }
    }
}

.h5-title {
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 999;

    >img {
        &:nth-child(1) {
            width: 16px;
            height: 16px;
            position: absolute;
            left: 20px;
        }

        &:nth-child(2){
            width: 120px;
        }
    }
    .logoh52{
        width: 107px !important;
        height: 33px;
    }
}

.ant-drawer-header {
    border-bottom: none;
}
.ant-drawer-title{
    >div>img{
        &:nth-child(1){
            width: 16px;
            height: 16px;
        }
        &:nth-child(2){
            width: 120px;
        }
    }
    .logoh52{
        width: 107px !important;
        height: 33px;
    }
}