.pay-modal {
    .ant-modal-content {
        width: 530px;
        height: fit-content;
        box-shadow: 0px 6px 6px 2px rgba(0, 0, 0, 0.22);
        border-radius: 20px;
        overflow: hidden;
    }

    .ant-modal-close {
        display: block !important;
        color: #FFFFFF;
    }

    .ant-modal-header {
        background: #4BAD6B;

        .ant-modal-title {
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 30px;
            text-align: center;
        }
    }

    .ant-modal-body {
        .paycode-con{
            >img {
                width: 120px;
                height: 120px;
            }
    
            >p {
                width: 90px;
                height: 34px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 17px;
                margin: 10px auto;
            }
            .yes-pay{
                width: 170px;
                height: 38px;
                background: #31A25E;
                border-radius: 6px;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 38px;
                cursor: pointer;
            }
        }
        .tip {
            padding: 10px 19px;
            background: #E0F2E5;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
            margin-top: 27px;
            text-align: left;
        }
        .body-dir{
            text-align: left;
            padding-left: 50px;
            .ant-radio-button-wrapper-checked{
                position: relative;
            }
            .ant-radio-button-wrapper-checked:after{
                position: absolute;
                content: url(../../assets/images/pay.png);
                right: 0;
                bottom: -10px;
            }
        }
        .title{
            font-size: 18px !important;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600 !important;
            color: #333333;
        }
    }
    .btn-box-h5{
        display: none;
    }
    .label {
        margin-bottom: 10px;
        .title {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 25px;
        }
        .ant-radio-button-wrapper{
            margin-right: 20px;
        }
        .content {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 25px;
        }
    }
}


@media screen and (max-width: 750px){
    .pay-modal{
        .ant-modal-header{
            background-color: #FFFFFF;
            border-bottom: none;
            .ant-modal-title {
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 600;
                color: #333333;
                text-align: center;
            }
        }
        .ant-modal-content{
            width: 325px !important;
            height: fit-content;
            margin: 0 auto;
            border-radius: 12px !important;
            .ant-modal-body{
                padding: 0 !important;
                border-radius: 12px !important;
                overflow: hidden;
                .title{
                    background-color: #FFFFFF;
                    
                }
                .body-dir{
                    padding: 18px 20px 0;
                    .label{
                        .title{
                            font-size: 16px !important;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                        }
                        .content{
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                        }
                        .ant-radio-button-wrapper{
                            margin-right: 0;
                        }
                        .ant-radio-button-wrapper:nth-of-type(1){
                            margin-right: 10px;
                        }
                        .ant-radio-button-wrapper-checked{
                            position: relative;
                        }
                        .ant-radio-button-wrapper-checked:after{
                            position: absolute;
                            content: url(../../assets/images/pay.png);
                            right: 0;
                            bottom: -10px;
                        }
                    }
                }
                .tip{
                    padding: 0 20px;
                    background-color: #FFFFFF;
                    span{
                        display: block;
                        width: 100%;
                        background: #E0F2E5;
                        padding: 8px 18px;
                    }
                }
                .btn-box-h5{
                    padding: 0;
                    width: 100%;
                    display: flex;
                    border-top: 1px solid #D8D8D8;
                    margin-bottom: 0 !important;
                    margin-top: 20px;
                    .ok, .cancel{
                        width: 50%;
                        border-radius: 0;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        height: 50px;
                        line-height: 50px;
                    }
                    .ok{
                        margin: 0;
                        color: #44A25A;
                        background-color: #FFFFFF;
                    }
                    .cancel{
                        color: #999999;
                        text-align: center;
                        border-right: 1px solid #D8D8D8;
                    }
                }
            }
            .ant-modal-close, .paycode-con{
                display: none !important;
            }
        }
        .btn-box-h5{
            display: block;
        }
    }
}