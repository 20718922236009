
@media screen and (min-width: 1440px) {
    .home-container-move {
        display: none;
    }

    .home-container {
        width: 100%;

        .banner,
        .banner2 {
            width: 100%;
            // height: 375px;
        }

        .banner {
            height: 779px;
            background: url('../../assets/images/bianzu5.png') no-repeat;
            background-size: 100% 100%;
            position: relative;
            // margin-top: -109px;
            z-index: 1;
            padding: 186px 75px 0 75px;

            .banner-body {
                width: 1290px;
                margin: auto;
                height: 200px;
                display: flex;
                margin-bottom: 30px;

                .banner-item {
                    width: 600px;
                    height: 200px;
                    display: flex;
                    background: rgba($color: #fff, $alpha: 0.84);
                    border-radius: 16px;

                    .item-img {
                        width: 280px;
                        height: 200px;
                    }

                    >div {
                        width: 320px;
                        height: 171px;
                        margin: 15px 20px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 6;
                        font-size: 18px;
                        overflow: hidden;
                        -webkit-box-orient: horizontal;
                        -webkit-box-pack: center;
                        -webkit-box-align: center;
                    }

                    &:nth-child(2) {
                        margin-left: 30px;
                    }
                }

                &:nth-child(1) {
                    justify-content: start;
                    margin-bottom: 30px;
                }

                &:nth-child(2) {
                    justify-content: flex-end;
                }
            }
        }

        .about-body {
            width: fit-content;
            margin: 0 auto;

            >h3 {
                margin-left: -30px;

                >img {
                    width: 67px;
                    height: 58px;
                    margin-right: 34px;
                }

                font-size: 30px;
                font-family: PingFangSC-Semibold,
                PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 58px;
            }

            .about-content {
                display: flex;

                .about-item {
                    width: 535px;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 30px;

                    &:nth-child(1) {
                        margin-right: 70px;
                    }
                }
            }
        }

        .intros {
            height: 855px;
            background: url('../../assets/images/bianzu7.png') no-repeat;
            background-size: 100% 100%;
            padding: 135px 120px 0;

            .intro-body {
                display: flex;
                justify-content: space-between;
                width: fit-content;
                margin: 0 auto;

                .intro-item {
                    cursor: pointer;
                    background: #ffffff;
                    width: 380px;
                    padding: 20px 20px 0 20px;
                    margin-right: 30px;
                    height: 100%;
                    border-radius: 16px;
                    >img {
                        width: 340px;
                        height: 240px;
                    }

                    >p {
                        margin: 12px 0;
                        font-size: 20px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        line-height: 28px;
                        text-align: center;
                    }

                    .intro-content {
                        width: 290px;
                        margin: 0 25px;
                        padding: 20px;
                        margin-bottom: -30px;
                        background: #FCD34C;
                        border-radius: 10px;
                    }

                    &:nth-last-child(1) {
                        margin-right: 0;
                    }
                }
            }
        }

        .time {
            height: 626px;
            background: url('../../assets/images/lujing4.png') no-repeat;
            background-size: cover;
            margin-top: -251px;
            padding-top: 182px;

            .title {
                font-size: 30px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 42px;
                letter-spacing: 2px;
                text-align: center;
                position: relative;
                width: fit-content;
                margin: 0 auto 70px;
                span{
                    position: absolute;
                    bottom: 0;
                    left: 2px;
                }
                .bor {
                    width: 100px;
                    height: 27px;
                    background: #31a25e;
                    opacity: .5;
                    // position: absolute;
                    // left: calc(50% - 48px);
                    // margin-top: -21px;
                }
            }

            .time-img {
                text-align: center;
                
                img{
                    max-width: 1280px;
                    margin: 0 auto;
                }
            }
        }

        .organizer {
            .organizer-item {
                width: 1280px;
                display: flex;
                flex-direction: column;
                margin: 0 auto;

                .title {
                    margin-top: 20px;
                    >img {
                        width: 17px;
                        height: 40px;
                        margin-right: 20px;
                    }

                    text-shadow: 0px 0px #000;
                    font-size: 30px;
                    font-family: PingFangSC-Medium,
                    PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    // line-height: 93px;
                    margin-bottom: 25px;
                    text-shadow: 0px 0px #000;
                }

                .item-body {
                    padding: 0 0 0 40px;
                    display: flex;
                    flex-wrap: wrap;
                    img {
                        flex-wrap: wrap;
                        width: 280px;
                        height: 100px;
                        margin-bottom: 8px;
                        
                    }
                    
                    >div{
                        width: 280px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 600;
                        color: #666666;
                        margin-bottom: 50px;
                        text-align: center;
                        margin-left: 30px;
                    }
                    >div:nth-of-type(4n + 1){
                        margin-left: 0;
                    }
                }
            }
        }

        .all-organizer{
            width: 1280px;
            margin: 20px auto 118px;
            display: flex;
            .maintitle{
                font-size: 34px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #31A25E;
                text-align: center;
            }
            .innovate{
                flex: 1;
                .organizer-item{
                    width: 100%;
                    padding: 0 !important;
                    .item-body{
                        padding: 0 15px;
                        text-align: center;
                        justify-content: space-between;
                        >div{
                            margin-left: 0 !important;
                        }
                        // >div:nth-of-type(even){
                        //     margin-left: 30px;
                        // }
                    }
                }
            }
            .innovate:nth-of-type(2){
                border-left: 1px solid #EEEEEE;
                padding-left: 15px;
            }
            
        }
        .problem {
            padding: 35px 120px 0 120px;
            background: rgb(158, 213, 172);
            margin-bottom: 20px;

            .problem-body {
                width: 1200px;
                margin: 0 auto;
                padding-bottom: 102px;

                .problem-title {
                    >img {
                        width: 17px;
                        height: 93px;
                        margin-right: 20px;
                    }

                    text-shadow: 0px 0px #000;
                    font-size: 30px;
                    font-family: PingFangSC-Medium,
                    PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 93px;
                }

            }

        }
    }
}

@media screen and (min-width: 751px) and (max-width: 1439px) {
    .home-container-move {
        display: none;
    }

    .home-container {
        .banner {
            width: 100%;
            height: 779px;
            background: url('../../assets/images/bianzu5.png') no-repeat;
            background-size: 100% 100%;
            position: relative;
            z-index: 1;
            padding: 18.9% 5.2% 0 5.2%;

            .banner-body {
                display: flex;
                height: 25.7%;
                justify-content: flex-end;

                .banner-item {
                    display: flex;
                    width: 41.7%;
                    height: 150px;
                    background: rgba($color: #fff, $alpha: 0.84);
                    border-radius: 16px;
                    >img {
                        height: 100%;
                    }

                    >div {
                        font-size: 18px;
                        width: 53.3%;
                        padding: 1.5% 3.3%;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 5;
                        -webkit-box-orient: horizontal;
                        -webkit-box-pack: center;
                        -webkit-box-align: center;
                    }

                    &:nth-child(1) {
                        margin-right: 2.1%;
                    }
                }

                &:nth-child(1) {
                    margin-bottom: 3.9%;
                    justify-content: flex-start;
                }
            }
        }

        .about-body {
            >h3 {
                margin-left: 8.1%;

                >img {
                    width: 4.7%;
                    height: 4.7%;
                    margin-right: 3%;
                }

                font-size: 30px;
                font-family: PingFangSC-Semibold,
                PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 58px;
            }

            .about-content {
                padding: 0 10.4% 3% 10.4%;
                display: flex;
                justify-content: space-between;

                .about-item {
                    width: 37.2%;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
            }
        }

        .intros {
            height: 855px;
            background: url('../../assets/images/bianzu7.png') no-repeat;
            background-size: 100% 100%;
            padding: 180px 8.3% 0 8.3%;

            .intro-body {
                display: flex;
                justify-content: space-between;

                .intro-item {
                    background: #fff;
                    width: 26.4%;
                    height: 100%;
                    padding: 1.4%;
                    border-radius: 16px;
                    >img {
                        width: 100%;
                    }

                    >p {
                        font-size: 20px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        line-height: 28px;
                        text-align: center;
                        margin: 12px 0;
                    }

                    .intro-content {
                        padding: 20px;
                        background: #FCD34C;
                        margin-bottom: -30px;
                        border-radius: 10px;
                    }
                }
            }
        }

        .time {
            height: 626px;
            background: url('../../assets/images/lujing4.png') no-repeat;
            background-size: cover;
            margin-top: -192px;
            padding-top: 182px;

            .title {
                font-size: 30px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 42px;
                letter-spacing: 2px;
                text-align: center;
                position: relative;
                width: fit-content;
                margin: 0 auto 70px;
                span{
                    position: absolute;
                    bottom: 0;
                    left: 2px;
                }
                .bor {
                    width: 100px;
                    height: 27px;
                    background: #31a25e;
                    opacity: .5;
                    // position: absolute;
                    // left: calc(50% - 48px);
                    // margin-top: -21px;
                }
            }

            .time-img {
                padding: 0 17%;
                text-align: center;

                >img {
                    max-width: 100%;
                }
            }
        }

        .organizer {
            .organizer-item {
                padding: 0 8.1%;

                .title {
                    >img {
                        margin-right: 3%;
                    }

                    text-shadow: 0px 0px #000;
                    font-size: 30px;
                    font-family: PingFangSC-Medium,
                    PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 93px;
                    margin-bottom: 3%;
                    text-shadow: 0px 0px #000
                }

                .item-body {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 0 3.3%;

                    img {
                        width: 100%;
                        margin-bottom: 8px;
                        // height: 100px;
                    }
                    >div{
                        width: 25%;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 600;
                        color: #666666;
                        margin-bottom: 50px;
                        text-align: center;
                        padding: 0 15px;
                        // display: flex;
                        // flex-direction: column;
                        // justify-content: space-between;
                    }
                }
            }
        }
        .all-organizer{
            // width: 1200px;
            margin: 20px auto 118px;
            display: flex;
            padding: 0 8.1%;
            .maintitle{
                font-size: 34px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #31A25E;
                text-align: center;
            }
            .innovate{
                flex: 1;
                .organizer-item{
                    width: 100%;
                    padding: 0 !important;
                    .item-body{
                        padding: 0 0 0 15px;
                        display: flex;
                        width: 100%;
                        >div{
                            width: 50%;
                            padding-right: 30px;
                            // display: flex;
                            // flex-direction: column;
                            // justify-content: space-between;
                            text-align: center;
                        }
                    }
                }
            }
            .innovate:nth-of-type(2){
                border-left: 1px solid #EEEEEE;
                padding-left: 15px;
            }
            
        }
        .problem {
            padding: 35px 0;
            background: rgb(158, 213, 172);

            .problem-body {
                .problem-title {
                    padding-left: 8.1%;

                    >img {
                        margin-right: 3%;
                    }

                    text-shadow: 0px 0px #000;
                    font-size: 30px;
                    font-family: PingFangSC-Medium,
                    PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 93px;
                    margin-bottom: 3%;
                }

                >div {
                    &:nth-last-child(1) {
                        padding: 0 11.1%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 360px) and (max-width: 750px) {
    .home-container {
        display: none;
    }

    .home-container-move {
        display: block;
        margin-top: 44px;
        #test {
            // height: 178px;
        }

        .banner {
            display: flex;
            flex-wrap: wrap;

            .banner-item {
                padding: 30px 10px;
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;

                .item-img {
                    width: 100%;
                    margin-bottom: 32px;
                }

                &:nth-child(1),
                &:nth-child(4) {
                    background: rgba($color: #FCD34C, $alpha: .39);
                }
            }
        }

        .about-body {
            >h3 {
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 75px;
                text-align: center;
            }

            .about-content {
                display: flex;
                width: fit-content;
                margin: 0 auto;
                margin-bottom: 40px;
                justify-content: space-around;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;

                .about-item {
                    font-size: 14px;
                    width: 48%;
                    padding: 0 2.1%;

                    &:nth-child(2) {
                        margin-right: 0;
                    }
                }

            }
        }

        .intros {
            background: #80C994;
            padding: 20px 5.3%;

            .intro-body {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                width: fit-content;
                margin: 0 auto;

                .intro-item {
                    background: #ffffff;
                    padding: 20px 10px 0 10px;
                    height: 100%;
                    width: 48%;
                    margin-bottom: 50px;
                    border-radius: 8px;

                    >img {
                        width: 100%;
                        // height: 198px;
                    }

                    >p {
                        margin: 12px 0;
                        font-size: 14px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        text-align: center;
                    }

                    .intro-content {
                        font-size: 12px;
                        padding: 5px;
                        margin-bottom: -30px;
                        background: #FCD34C;
                        border-radius: 6px;
                    }

                    &:nth-last-child(1) {
                        margin-right: 0;
                    }

                    &:nth-child(1) {
                        margin-right: 4%;
                    }
                }
            }
        }

        .time {
            margin-top: -100px;
            padding-top: 120px;

            .title {
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                // line-height: 42px;
                letter-spacing: 2px;
                text-align: center;
                position: relative;
                width: fit-content;
                margin: 0 auto ;
                display: flex;
                align-items: flex-end;
                span{
                    // position: absolute;
                    // bottom: 0;
                    // left: 0;
                }
                .bor {
                    width: 60px;
                    height: 16px;
                    background: #31a25e;
                    opacity: .5;
                    position: absolute;
                    // left: calc(50% - 30px);
                    // margin-top: -21px;
                }
            }

            .time-img {
                text-align: center;
                padding: 22px 10% 10% 10%;
                max-width: 100%;
                img{
                    width: 100%;
                }
            }
        }

        .organizer {
            padding: 0 5.3%;

            .organizer-item {
                display: flex;
                flex-direction: column;
                margin: 0 auto;

                .title {
                    >img {
                        width: 8px;
                        height: 25px;
                        margin-right: 20px;
                    }

                    text-shadow: 0px 0px #000;
                    font-size: 18px;
                    font-family: PingFangSC-Medium,
                    PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 40px;
                    margin-bottom: 25px;
                }

                .item-body {
                    // padding: 0 40px;
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    padding-left: 28px;
                    >div{
                        width: 33%;
                        >img {
                            width: 85%;
                            // height: 25px;
                            margin: 0 3% 0 0;
                        }
                        >div{
                            width: 85%;
                            font-size: 8px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #666666;
                            text-align: center;
                            margin-bottom: 20px;
                        }
                    }
                    
                }
            }
        }

        .all-organizer{
            .maintitle{
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #31A25E;
                text-align: center;
                margin: 30px 0 20px 0 ;
            }
            .item-body{
                >div{
                    // display: flex;
                    // flex-direction: column;
                    // justify-content: space-between;
                }
            }
        }
        
        .problem {
            margin-bottom: 20px;

            .problem-body {
                .problem-title {
                    padding-left: 5.3%;

                    >img {
                        margin-right: 6%;
                        width: 8px;
                        height: 40px;
                    }

                    text-shadow: 0px 0px #000;
                    font-size: 18px;
                    font-family: PingFangSC-Medium,
                    PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 40px;
                    margin-bottom: 3%;
                }

                >div {
                    &:nth-last-child(1) {
                        padding: 0 11.1%;
                    }
                }
            }
        }
    }
}