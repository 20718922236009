.business-nofund, .business-fund, .innovate-con{
    background: #FFFFFF;
    box-shadow: 0px 4px 6px 4px #EEF0F1;
    border-radius: 30px;
    margin-top: 40px;
    padding: 70px 130px 70px;
    .title{
        width: fit-content;
        font-size: 30px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        position: relative;
        margin-bottom: 70px;
        .bor{
            width: 100%;
            height: 21px;
            background: #31A25E;
            opacity: 0.7;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
    .title2{
        margin: 70px 0 30px 0;
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img{
            cursor: pointer;
        }
    }
    .header-tip{
        color: #4BAD6B;
        margin: 50px 0;
    }
    .wiki{
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin: 30px 0 0 110px;
    }
    .url{
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-left: 183px;
    }
    .title3{
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 34px;
        img{
            cursor: pointer;
        }
    }
    .double-input-div{
        font-size: 16px;
    }
    .ant-tabs-nav .ant-tabs-nav-list{
        width: 100%;
        .ant-tabs-tab{
            flex: 1;
            margin: 0;
            .ant-tabs-tab-btn{
                margin: 0 auto;
            }
        }
        .ant-tabs-tab-btn{
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
            font-weight: 500;
        }
        .ant-tabs-ink-bar{
            height: 4px;
            background-color: #31A25E;
        }
    }
    .ant-tabs-nav::before{
        border-bottom: 4px solid #F1F1F1 !important;
    }
    .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav{
        margin-bottom: 30px !important;
    }

    .ant-input{
        height: 50px;
        background: #FFFFFF;
        border-radius: 4px;
        font-size: 18px;
        resize:none
    }
    .ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover{
        border-color: #ff4d4f !important;
    }

    .ant-checkbox-inner, .ant-checkbox, .ant-checkbox-input, .ant-checkbox-inner, .ant-checkbox-checked::after{
        border-radius: 50%;
        border-color: #31A25E;
    }
    .ant-checkbox-checked .ant-checkbox-inner{
        border-color: #31A25E;
        background-color: #31A25E;
    }
    .foundtime, .zone{
        display: flex;
        align-items: center;
    }
}

@media (max-width: 1199px){
    .business-nofund, .business-fund, .innovate-con {
        width: 100%;
        padding: 40px;
        .wiki {
            font-size: 20px;
        }
        .url {
            font-size: 18px;
        }
        .header-tip{
            margin: 20px 0;
        }
    }
}

@media screen and (min-width: 751px) and (max-width: 998px)  {
    .business-nofund, .business-fund, .innovate-con {
        .wiki {
            font-size: 18px;
        }
        .url {
            font-size: 16px;
        }
        .header-tip{
            margin: 15px 0;
        }
        .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab-btn{
            font-size: 18px;
        }
    }
}

@media (max-width: 751px){
    .business-nofund, .business-fund, .innovate-con {
        padding: 20px;
        .title2 {
            font-size: 20px;
            margin: 20px 0;
        }
        .wiki {
            font-size: 16px;
            margin: 15px;
        }
        .url {
            font-size: 14px;
            margin: 15px 25px;
        }
        .header-tip {
            font-size: 12px;
        }
        .title3 {
            font-size: 20px;
            padding-bottom: 10px;
            img{
                width: 24px;
            }
        }
    }
}

@media (max-width: 480px) {
    .business-nofund, .business-fund, .innovate-con {
        .ant-tabs-nav .ant-tabs-nav-list{
            width: auto;
            .ant-tabs-tab {
                margin-right: 10px;
            }
        }
        .wiki {
            font-size: 14px;
            margin: 10px;
        }
        .url {
            font-size: 12px;
            margin: 10px 20px;
        }
        .title {
            font-size: 20px;
            margin-bottom: 30px;
            .bor{
                height: 12px;
            }
        }
        .title2 {
            font-size: 16px;
            margin: 10px 0;
            img{
                width: 20px;
            }
        }
    }
}


