.sign-up-challenge-topics{
    width: 100%;
    margin: 0 auto;
    padding: 50px 7% 100px;
    .sign-up-challenge-topics-content{
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .navlist{
            width: 160px;
            height: fit-content;
            margin-right: 30px;
            box-shadow: 0px 2px 4px 2px #EEF0F1;
            .item{
                height: 70px;
                line-height: 70px;
                text-align: center;
                border-bottom: 1px solid #F1F1F1;
                font-size: 22px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                cursor: pointer;
            }
            .item-active{
                color: #31A25E;
            }
        }
        .topics-cont{
            flex: 1;
            box-shadow: 0px 2px 4px 2px #EEF0F1;
            padding: 50px 50px 130px;
            .title{
                font-size: 24px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 600;
                color: #333333;
                text-align: center;
            }
            .intro{
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 22px;
                margin-top: 30px;
            }
            .topics-item{
                margin-top: 50px;
                .title{
                    height: 70px;
                    background: #99D4A9;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 30px;
                    font-size: 22px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    img{
                        cursor: pointer;
                    }
                }
                .content{
                    background: #F9F9F9;
                    padding: 50px 40px;
                    // display: none;
                    .content-item{
                        display: flex;
                        margin-bottom: 30px;
                        .titles{
                            width: 110px;
                            font-size: 18px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                        }
                        .cont{
                            flex: 1;
                            font-size: 16px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                            word-break: break-all;
                        }
                    }
                    .sign-btn, .sign-btn-no{
                        width: 160px;
                        height: 50px;
                        background: #4BAD6B;
                        border-radius: 6px;
                        font-size: 18px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 50px;
                        margin: 60px auto 0;
                        cursor: pointer;
                    }
                    .sign-btn-no{
                        background-color: #ddd;
                        color: #999;
                        cursor: default;
                    }
                }
                .content-active{
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 750px){
    .sign-up-challenge-topics{
        padding: 10px 20px;
        min-height: calc( 100vh - 224px);
        box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.08);
        margin-top: 44px;
        .sign-up-challenge-topics-content{
            .navlist{
                width: 68px;
                margin-right: 20px;
                .item{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    height: 40px;
                    line-height: 40px;
                }
            }
            .topics-cont{
                box-shadow: none;
                padding: 0;
                .title{
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    margin-top: 30px;
                }
                .intro{
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 17px;
                    margin-top: 14px;
                }
                .topics-item{
                    margin-top: 30px;
                    .title{
                        height: 36px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        padding: 0 10px;
                        img{
                            width: 18px;
                        }
                    }
                    .content{
                        padding: 14px 10px 30px;
                        .content-item{
                            margin-bottom: 16px;
                            .titles{
                                width: 85px;
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #333333;
                            }
                            .cont{
                                font-size: 12px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #333333;
                                word-break: break-all;
                            }
                        }
                        .sign-btn, .sign-btn-no{
                            margin: 30px auto 0;
                            width: 120px;
                            height: 34px;
                            background: #31A25E;
                            border-radius: 4px;
                            font-size: 14px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #FFFFFF;
                            line-height: 34px;
                            
                        }
                        .sign-btn-no{
                            background-color: #ddd;
                            color: #999;
                            cursor: default;
                        }
                    }
                }
            }
        }
        
    }
}