.paysuccess-modal {
    .ant-modal-content {
        width: 440px;
        height: 283px;
        background: #FFFFFF;

        .ant-modal-body {
            width: 100%;
            height: 100%;
            box-shadow: 0px 6px 6px 2px rgba(0, 0, 0, 0.22);
            border-radius: 20px;
            >p {
                margin: 20px 0;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 25px;
            }

            .btn {
                width: 190px;
                height: 40px;
                background: #31A25E;
                border-radius: 4px;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 40px;
                text-align: center;
                margin: 0 auto;
                cursor: pointer;
                letter-spacing: 2px;
            }
        }
    }
}

@media screen and (min-width: 360px) and (max-width: 750px){
    .paysuccess-modal {
        .ant-modal-content {
            width: 325px !important;
            height: 243px;
            background: #FFFFFF;
            margin: 0 auto;
            border-radius: 12px !important;
            .ant-modal-body {
                width: 100%;
                height: 100%;
                box-shadow: 0px 6px 6px 2px rgba(0, 0, 0, 0.22);
                border-radius: 12px !important;
                // padding-left: 0;
                // padding-right: 0;
                // padding-bottom: 0;
                padding: 34px 0 0 0 !important;
                overflow: hidden;
                >p {
                    margin: 24px 0;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 25px;
                }
    
                .btn {
                    width: 100%;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 0;
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #44A25A;
                    line-height: 40px;
                    text-align: center;
                    margin: 0 auto;
                    cursor: pointer;
                    letter-spacing: 2px;
                    border-top: 1px solid #DDDDDD;

                }
            }
        }
    }
}