.h5-tip{
    .ant-message-notice-content{
        background: #000;
        opacity: .49;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        margin-top: 34vh;
    }
}