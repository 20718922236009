.notice-list{
    width: 100%;
    padding: 50px 8.3% 100px;
    box-sizing: border-box;
    .note-item{
        width: 100%;
        
        .time{
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #31A25E;
            margin-bottom: 20px;
        }
        .content{
            // height: 265px;
            background: #FFFFFF;
            box-shadow: 0px 4px 6px 4px #EEF0F1;
            border-radius: 30px;
            padding: 40px 30px;
            border:2px solid #FFFFFF;
            margin-bottom: 70px;
            .title{
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
            }
            .intro{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 24px;
                margin-top: 20px;
                word-break: break-all;
            }
            .zhankai{
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #31A25E;
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-top: 20px;
                img{
                    margin-right: 6px;
                }
            }
        }
        .content-active{
            cursor: pointer;
        }
        .content:hover{
            border:2px solid #31A25E;
        }
    }
    .note-item:nth-last-of-type(1){
        margin-bottom: 100px;
    }
    .ant-pagination{
        display: flex;
        justify-content: center;
    }
    .nodata{
        text-align: center;
        margin: 200px auto 169px;
        .text{
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            margin-top: 50px;
        }
    }
}
@media screen and (max-width: 750px){
    .notice-list{
        width: 100%;
        padding: 22px 15px 0;
        margin-top: 44px;
        .note-item{
            margin-bottom: 30px;
            .time{
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #31A25E;
                margin-bottom: 15px;
            }
            .content{
                background: #FFFFFF;
                box-shadow: 0px 2px 8px 0px #DDDDDD;
                border-radius: 10px;
                padding: 15px;
                margin-bottom: 30px;
                .title{
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                }
                .intro{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                    margin-top: 12px;
                }
                .zhankai{
                    margin-top: 5px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #31A25E;
                    img{
                        margin-right: 4px;
                        width: 14px;
                    }
                }
            }
            
        }
        .note-item:nth-last-of-type(1){
            margin-bottom: 30px;
        }
        .mini{
            margin-bottom: 30px;
        }
        .nodata{
            text-align: center;
            margin: 133px auto 165px;
            img{
                width: 198px;
            }
            .text{
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                margin-top: 30px;
            }
        }
    }
}