@media screen and (min-width: 1440px) {
    .video {
        display: block;

        .video-box {
            width: 1200px;
            margin: 50px auto 0;
            display: flex;
            flex-wrap: wrap;

            .video-item {
                width: 270px;
                height: 318px;
                background: #FFFFFF;
                box-shadow: 0px 2px 4px 2px #EEF0F1;
                border-radius: 10px;
                margin: 0 40px 50px 0;
                overflow: hidden;
                cursor: pointer;

                &:nth-child(4n) {
                    margin-right: 0;
                }

                >img {
                    width: 270px;
                    height: 152px;
                }

                .video-body {
                    height: 128px;
                    padding: 18px 20px 10px 20px;

                    .video-title {
                        font-size: 16px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        line-height: 22px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                    }

                    .video-content {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 20px;
                        margin: 14px 0 25px 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                    }


                }

                .video-time {
                    border-top: 1px solid #f1f1f1;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 37px;
                    padding: 0 20px;
                }
            }
        }


        .ant-pagination {
            margin: 30px auto 100px;
            width: fit-content;

            .ant-pagination-item-active a {
                color: #fff;
                background: #31A25E;
            }
        }
    }

    .video-h5 {
        display: none;
    }
}

@media screen and (min-width: 750px) and (max-width: 1439px) {
    .video {
        display: block;

        .video-box {
            padding: 0 8.3%;
            width: 100%;
            margin: 50px auto 0;
            display: flex;
            flex-wrap: wrap;

            .video-item {
                width: 22.5%;
                height: 318px;
                background: #FFFFFF;
                box-shadow: 0px 2px 4px 2px #EEF0F1;
                border-radius: 10px;
                margin: 0 3.3% 50px 0;
                overflow: hidden;
                cursor: pointer;

                &:nth-child(4n) {
                    margin-right: 0;
                }

                >img {
                    width: 100%;
                    height: 152px;
                }

                .video-body {
                    height: 128px;
                    padding: 18px 20px 10px 20px;

                    .video-title {
                        font-size: 16px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        line-height: 22px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                    }

                    .video-content {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 20px;
                        margin: 14px 0 25px 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                    }
                }

                .video-time {
                    border-top: 1px solid #f1f1f1;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 37px;
                    padding: 0 20px;
                }

            }
        }


        .ant-pagination {
            margin: 30px auto 100px;
            width: fit-content;

            .ant-pagination-item-active a {
                color: #fff;
                background: #31A25E;
            }
        }
    }

    .video-h5 {
        display: none;
    }
}

@media screen and (min-width: 360px) and (max-width: 750px) {
    .video {
        display: none;
    }

    .video-h5 {
        display: block;
        margin-top: 44px;
        padding: 0 16px;

        .section-title {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 52px;
            text-align: center;
            position: fixed;
            background: #fff;
            width: calc(100% - 32px);
            border-bottom: 1px solid #f1f1f1;
        }

        .video-body {
            padding: 44px 4px 0 4px;

            .video-item {
                margin-top: 0;
                margin-bottom: 20px;
                background: #FFFFFF;
                box-shadow: 0px 0px 4px 0px #D8D8D8;
                border-radius: 10px;
                padding: 12px 17px 10px 18px;
                cursor: pointer;

                >img {
                    width: 100%;
                }

                .video-title {
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 20px;
                    margin-top: 12px;
                }

                .video-content {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 17px;
                    margin: 6px 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }

                .video-time {
                    font-size: 10px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 14px;
                    text-align: right;
                }
            }
            .video-item:nth-of-type(1){
                margin-top: 20px;
            }
        }
    }
}